export const Branding: Branding = {
    primary: "#2a9fd6",
    secondary: "#272822",
    transparentBackground: "rgba(0,0,0,0.75)",
    transparentGreyBackground: "rgba(40,40,40,0.75)",
    superDarkBackground: "#282828",
    darkBackground: "#373737",
    darkTransparentBackground: "rgba(55,55,55,0.8)",
    darkHighlyTransparentBackground: "rgba(55,55,55,0.15)",
    draggableBackground: "#444444",
    greyBlue: "rgb(36, 159, 212)",
    darkRed: "rgba(113, 2, 9, 0.8)",
    darkGreen: "rgba(2, 113, 9, 0.8)",
    twentyfouriRed: "#fb0148",
    yellow: "#fbb801",
    nearBlack: "#181818",
    // These can be used via white, and black keywords, this just helps unification across the project
    white: "#ffffff",
    black: "#000000"
};

export default Branding;