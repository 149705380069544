import React, { RefObject } from "react";
import { ReactNode } from "react";
import { Button, Col, Modal, OverlayTrigger,  Row, Spinner, Tooltip } from "react-bootstrap";
import { FileEarmarkArrowDownFill as SaveIcon, Trash as ClearIcon } from "react-bootstrap-icons";
import { Prompt } from "react-router";
import styled from "styled-components";

import { itemSearch, getEntityAssociations, postEntityAssociations, getHeroItemMetadata } from "../../utils/Requests";
import Logger from "../../utils/Logger";
import * as constants from "../../constants";
import SearchTable from "./SearchTable";
import AssociationGrid from "./AssociationGrid";
import SearchForm from "./SearchForm";
import { getCustomerConfig } from "../../config/Customers";
import { seedIdCheck } from "../../utils/Utils";
import Branding from "../../config/Branding";

const EditorialRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin: 10px 0;
    padding: 5px 5px;
`;

const SearchDiv = styled.div`
    overflow: auto;
    height: calc(100vh - 280px);
    width: 100%;
`;

const SearchCountDiv = styled.div`
    text-align: center;
    width: 100%;
    margin: 5px;
    height: 20px;
`;

const Associations = styled.div`
    overflow: auto;
    height: calc(100vh - 285px);
    width: 100%;
    border-radius: 3px;
    background: ${Branding.nearBlack};
    margin-bottom: 5px;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

const BlockingDiv = styled.div<BlockingDivProps>`
    pointer-events: ${props => props.blocking ? "none" : "all"};
    opacity: ${props => props.blocking ? 0.5 : 1};
    width: 100%;
`;

const Spacer = styled.div`
    margin: 0 5px;
`;

type SelectionTabProperties = {
    customer: string,
    editorial?: boolean,
    alertCallback: (status: "warning" | "success", msg: string) => void,
    selectionCallback: (selectedItems: Array<SearchItem>) => void,
    selectedEditorialID?: string,
    selectedEditorialName?: string
}

type SelectionTabState = {
    originalItems: Array<EntityAssociation>,
    selectedItems: Array<SearchItem>,
    removedItems: Array<SearchItem>,
    searchResults: Array<SearchItem>,
    noResults: boolean,
    loading: boolean,
    showModal: boolean,
    unsaved: boolean
}


export default class SelectionTab extends React.Component<SelectionTabProperties, SelectionTabState> {

    private searchFormElement: RefObject<SearchForm>;
    private groupId: string;
    private groupName: string;

    constructor(props: SelectionTabProperties) {
        super(props);

        this.searchFormElement = React.createRef<SearchForm>();

        if (this.props.selectedEditorialID) {
            this.groupId = this.props.selectedEditorialID;
        } else {
            this.groupId = "";
        }

        if (this.props.selectedEditorialName) {
            this.groupName = this.props.selectedEditorialName;
        } else {
            this.groupName = "";
        }

        this.state = {
            originalItems: [],
            searchResults: [],
            selectedItems: [],
            removedItems: [],
            noResults: false,
            loading: true,
            showModal: false,
            unsaved: false
        };
    }

    public componentDidMount(): void {
        // Add a slight delay before population due to population of customer props
        setTimeout(() => { this.populateItems();}, 500);
    }

    private async populateItems() {
        if (this.props.customer !== "") {
            const customerConfig = getCustomerConfig(this.props.customer);
            await getEntityAssociations(customerConfig, this.groupId).then((response) => {
                if (response.ok) {
                    response.json().then(async (responseJSON) => {
                        const originalAssociations = responseJSON;
                        const items = responseJSON.map((item: EntityAssociation) => {
                            return {
                                "id": seedIdCheck(this.props.customer, item.thingId)
                            };
                        });

                        const enrichedItems = await getHeroItemMetadata(
                            customerConfig,
                            items
                        );

                        this.setState({
                            originalItems: originalAssociations,
                            selectedItems: enrichedItems,
                            loading: false,
                            unsaved: false
                        }, this.itemsCallback);
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    public reset = (): void => {
        this.setState({
            originalItems: [],
            searchResults: [],
            selectedItems: [],
            noResults: false,
            loading: true,
            showModal: false,
            unsaved: false
        }, () => {
            if ( this.searchFormElement.current) {
                this.searchFormElement.current.reset();
            }

            this.populateItems();
        });
    }

    private resetSearch = (): void => {
        this.setState({
            searchResults: []
        });
    }

    private search = async (searchTerm: string): Promise<void> => {
        const searchResults = await itemSearch(getCustomerConfig(this.props.customer), searchTerm);

        this.setState({
            searchResults,
            noResults: searchResults.length === 0
        });
    }

    private addToGroup = (item: SearchItem): void => {
        const newSelection = this.state.selectedItems;

        item["id"] = seedIdCheck(this.props.customer, item.id);

        if (newSelection.indexOf(item) < 0) {
            newSelection.push(item);
        }

        this.setState({
            selectedItems: newSelection,
            unsaved: true
        });
    }

    private removeFromGroup = (itemToRemove: SearchItem): void => {
        let newSelection = this.state.selectedItems;

        newSelection = newSelection.filter((item) => {
            return item !== itemToRemove;
        });

        const removedItems = this.state.removedItems;
        removedItems.push(itemToRemove);

        this.setState({
            selectedItems: newSelection,
            removedItems,
            unsaved: true
        });
    }

    private saveGroup = async (): Promise<void> => {
        const entities: EntityAssociation[] = this.state.selectedItems.map(item => {
            return {
                entityId: this.groupId,
                entityName: this.groupName,
                entityType: `${constants.GROUP_ENTITY_TYPE}`,
                thingId: item.id,
                thingName: item.name,
                thingType: item.typeName
            };
        });

        this.setState({
            loading: true
        });

        await postEntityAssociations(
            getCustomerConfig(this.props.customer),
            this.state.originalItems,
            entities
        ).then(async (response) => {
            if (response.ok) {
                this.props.alertCallback("success", "Successfully saved lane items");
                setTimeout(() => { this.populateItems(); }, 1000);
            }
        }).catch((error) => {
            Logger.error("Error: ", error);
            this.setState({
                loading: false
            }, () => {
                this.props.alertCallback("warning", "Error saving lane items");
            });
        });
    }

    private itemsCallback = () => {
        this.props.selectionCallback(this.state.selectedItems);
    }

    private clearGroup = () => {
        this.setState({
            selectedItems: [],
            showModal: false
        }, this.itemsCallback);
    }

    private displayModal = (show: boolean) => {
        this.setState({
            showModal: show
        });
    }

    public render(): ReactNode {
        if (this.groupId) {
            return (
                <>
                <Prompt
                    when={this.state.unsaved}
                    message={"Changes have not been saved, do you wish to continue?"}
                />
                <Modal show={this.state.showModal} centered backdrop="static" onHide={() => { this.displayModal(false); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove all items</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        This will remove all the currently selected items, however the change won't be persisted until you've saved
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.displayModal(false); }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.clearGroup}>
                            Remove All Items
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col md={4} className="mx-auto">
                        <EditorialRow>
                            {this.state.loading && <SpinnerDiv>
                                <Spinner animation="border" style={{color: "white"}}/>
                            </SpinnerDiv>}
                            <BlockingDiv blocking={this.state.loading}>
                                <SearchForm
                                    ref={this.searchFormElement}
                                    resetCallback={this.resetSearch}
                                    searchCallback={this.search} />
                                <SearchDiv>
                                    <SearchTable
                                        items={this.state.searchResults}
                                        noResults={this.state.noResults}
                                        callback={this.addToGroup}
                                    />
                                </SearchDiv>
                                <SearchCountDiv>
                                    {this.state.searchResults.length > 0 && <>Showing {this.state.searchResults.length} results</>}
                                </SearchCountDiv>
                            </BlockingDiv>
                        </EditorialRow>
                    </Col>
                    <Col md={8}>
                        <EditorialRow>
                            {this.state.loading && <SpinnerDiv>
                                <Spinner animation="border" style={{color: "white"}}/>
                            </SpinnerDiv>}
                            <BlockingDiv blocking={this.state.loading}>
                                <Col md={12}>
                                    <Row className="justify-content-center">
                                        <h5>Selected Lane Items</h5>
                                        <Associations>
                                            <AssociationGrid items={this.state.selectedItems} removeCallback={this.removeFromGroup}/>
                                        </Associations>
                                    </Row>
                                    <Row className="flex-row-reverse">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="search-tooltip">Save Items To Lane</Tooltip>}>
                                            <Button onClick={this.saveGroup}><SaveIcon /></Button>
                                        </OverlayTrigger>
                                        <Spacer />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="search-tooltip">Remove All Items From Lane</Tooltip>}>
                                            <Button variant="danger" onClick={() => {this.displayModal(true);}}><ClearIcon /></Button>
                                        </OverlayTrigger>
                                    </Row>
                                </Col>
                                </BlockingDiv>
                        </EditorialRow>
                    </Col>
                </Row>
                </>
            );
        } else {
            return (<>Please select a lane to view/edit</>);
        }
    }

}
