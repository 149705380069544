import React, { ReactNode } from "react";
import {
    Button, ButtonGroup, Col, Container, Form, InputGroup,
    OverlayTrigger, Popover, Row, Spinner, Tooltip
} from "react-bootstrap";
import {
    Bookmark as PresetIcon,
    PlayFill as PreviewIcon,
    Trash as ClearIcon,
    FileEarmarkPlus as CreateIcon,
    FileEarmarkArrowDownFill as SaveIcon,
    ArrowClockwise as RefreshIcon,
    InfoCircleFill as InfoIcon,
    Search as SearchIcon,
    SortNumericDown as SortIdIcon,
    SortAlphaDown as SortNameIcon
} from "react-bootstrap-icons";
import styled from "styled-components";

import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";

import * as constants from "../constants";
import { SearchResults } from "../components/SearchResults";
import { getConfig, getSlotsForCustomer, postSlot, previewSlot } from "../utils/Requests";
import { generateGuid } from "../utils/Utils";
import Branding from "../config/Branding";
import SaveSlotModal from "../components/slots/SaveSlotModal";
import RandomField from "../components/fields/RandomField";
import InvalidSlotModal from "../components/slots/InvalidSlotModal";

const SpinnerDiv = styled.div`
    position: absolute;
    z-index: 10;
    min-height: 86vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Branding.transparentGreyBackground};
    border-radius: 3px;
`;

const EditorCol = styled(Col)`
    background: ${Branding.superDarkBackground};
    margin: 10px;
    border-radius: 3px;
    position: fixed;
`;

const PreviewCol = styled(Col)`
    margin-left: auto;
    text-align: center;
`;

const PreviewFieldsRow = styled(Row)`
    background: ${Branding.transparentBackground};
    z-index: 50;
    position: sticky;
    top: 63px;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-left: 0px;
`;

const FieldName = styled(InputGroup.Text)`
    background: black;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
`;

const FieldIcon = styled(InputGroup.Text)`
    background: black;
    padding-left: 10px;
    padding-right: 10px;
`;

const FieldValue = styled(Form.Control)`
    padding: 0.375rem 0.375rem;
`;

const EditorButton = styled(Button)`
    margin-left: 5px;
`;

type SlotsEditorState = {
    slots: Array<Slot>,
    availableSlotTypes: Array<SlotType>,
    slot?: Slot,
    slotId: string,
    description: string,
    slotName: string,
    slotType: string,
    slotExperiments: string,
    fallbackChart: string,
    create: boolean,
    userId?: string,
    seedId?: string,
    searchTerm?: string,
    items: Array<Record<string, any>>,
    error: Error | null,
    previewLoaded: boolean
    previewLoading: boolean,
    slotSaving: boolean,
    showSaveModal: boolean,
    sortOrder: "slotId" | "slotName",
    showInvalidJsonModal: boolean,
    invalidJsonData: string,
    tempStoreSlotId: string,
    origin: string,
    tempSlot?: Slot,
    slotIsDefault: boolean,
    slotZeroResultsOk: boolean,
    responseSize: number
}

type SlotsEditorProperties = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

export default class SlotsEditor extends React.Component<SlotsEditorProperties, SlotsEditorState> {

    private createSlotID: string = generateGuid();
    private newSlot: Slot = {
        slotId: `${this.createSlotID}`,
        slotName: "New slot",
        type: "unknown",
        description: "New slot",
        fallbackChart: "all",
        experiments: `[
    {
        "id": "${generateGuid()}",
        "userPercentage": 100,
        "isBaseRecipe": true,
        "size": 6,
        "title": "",
        "notes": "",
        "modelDefinitions": [
        ]
    }
]
        `,
        zeroResultsOk: false,
        isDefault: false,
        responseSize: 6
    }

    constructor(props: SlotsEditorProperties) {
        super(props);

        this.state = {
            availableSlotTypes: [],
            items: [],
            slots: [],
            description: "",
            slotId: "",
            slotName: "",
            slotType: "unknown",
            slotExperiments: "",
            fallbackChart: "",
            create: false,
            previewLoading: false,
            error: null,
            previewLoaded: false,
            showSaveModal: false,
            slotSaving: true,
            sortOrder: "slotName",
            showInvalidJsonModal: false,
            invalidJsonData: "",
            tempStoreSlotId: "",
            origin: "",
            slotIsDefault: false,
            slotZeroResultsOk: false,
            responseSize: 0
        };
    }

    public componentDidMount(): void {
        this.getSlotTypes();
    }

    private async getSlotTypes(): Promise<void> {
        await getConfig("slot_types").then((slotTypes: Record<string, any>) => {

            const availableSlotTypes: Array<SlotType> = [];
            for (const key in slotTypes) {
                availableSlotTypes.push({
                    key,
                    name: slotTypes[key]["name"],
                    shortName: slotTypes[key]["shortName"],
                    parameters: slotTypes[key]["parameters"]
                });
            }

            this.setState({
                availableSlotTypes
            });

        }).then(() => { this.getSlots(); });

    }

    private async getSlots(): Promise<void> {
        await getSlotsForCustomer(this.props.customerConfig).then((slots: Array<Slot>) => {
            let slot = slots[0];
            if (this.state.slot && !this.state.create) {
                slot = this.state.slot;
            }

            try {
                const experiments = JSON.parse(slot.experiments);
                const slotType = slot.type || "unknown";
                const slotExperiments = JSON.stringify(experiments, undefined, 2);

                this.setState({
                    slots,
                    slot,
                    slotId: slot.slotId,
                    slotName: slot.slotName,
                    description: slot.description,
                    slotType,
                    slotExperiments,
                    slotSaving: false,
                    create: false,
                    responseSize: slot.responseSize
                });
            } catch (error) {
                this.setState({
                    showInvalidJsonModal: true,
                    invalidJsonData: slot.experiments
                });
            }
        });
    }

    private setSlot = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const slotId = event.target.value;

        this.setSlotById(slotId);
    }

    private setSlotById(slotId: string) {
        const slot = this.state.slots.filter((slot) => {
            return slot.slotId === slotId;
        })[0];

        this.setState({
            tempSlot: slot
        });

        try {
            const experiments = JSON.parse(slot.experiments);
            const slotType = slot.type || "unknown";
            const jsonDefinition = JSON.stringify(experiments, undefined, 2);

            let slotIsDefault = false;
            if (slot.isDefault !== undefined) {
                slotIsDefault = slot.isDefault;
            }

            let slotZeroResultsOk = false;
            if (slot.zeroResultsOk !== undefined) {
                slotZeroResultsOk = slot.zeroResultsOk;
            }

            this.setState({
                slotId,
                slot,
                slotName: slot.slotName,
                slotType,
                description: slot.description,
                slotExperiments: jsonDefinition,
                create: false,
                slotIsDefault,
                slotZeroResultsOk,
                responseSize: slot.responseSize
            });
        } catch (error) {
            this.setState({
                showInvalidJsonModal: true,
                invalidJsonData: slot.experiments
            });
        }
    }

    private createNewSlot = (): void => {
        this.createSlotID = generateGuid();

        this.setState({
            slotId: this.createSlotID,
            slot: this.newSlot,
            slotName: this.newSlot.slotName,
            slotType: "unknown",
            description: "",
            slotExperiments: this.newSlot.experiments,
            create: true,
            responseSize: 6
        });
    }

    private saveSlot = async () => {
        this.setState({
            slotSaving: true
        });

        const slot: Slot = {
            slotId: this.state.slotId,
            slotName: this.state.slotName,
            description: this.state.description,
            type: this.state.slotType,
            fallbackChart: "all",
            experiments: this.state.slotExperiments,
            zeroResultsOk: this.state.slotZeroResultsOk,
            isDefault: this.state.slotIsDefault,
            responseSize: this.state.responseSize
        };

        this.setState({
            slot
        });

        await postSlot(
            this.props.customerConfig,
            this.state.slotId,
            this.state.slotName,
            this.state.slotExperiments,
            this.state.fallbackChart,
            slot
        ).then(() => {
            this.getSlots();
        });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            this.makeSlotPreviewRequest();
        }
    }

    private setUserId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            userId: callbackValue
        }, () => {
            if (submit) {
                this.makeSlotPreviewRequest();
            }
        });
    }

    private setSeedId = (callbackValue: string, submit: boolean): void => {
        const seedId = callbackValue;

        this.setState({
            seedId
        }, () => {
            if (submit) {
                this.makeSlotPreviewRequest();
            }
        });
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value
        });
    }

    private setDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            description: event.currentTarget.value
        });
    }

    private setResponseSize = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            responseSize: parseInt(event.currentTarget.value)
        });
    }

    private makeSlotPreviewRequest = async (): Promise<void> => {
        this.setState({
            previewLoading: true,
            previewLoaded: false,
            items: [],
            error: null
        });

        const previewSlotObj = `{
            "slotId": "${this.state.slotId}",
            "name": "${this.state.slotName}",
            "description": "${this.state.description}",
            "type": "${this.state.slotType}",
            "experiments": ${this.state.slotExperiments},
            "zeroResultsOk": false,
            "reponseSize": ${this.state.responseSize}
        }`;

        await previewSlot(
            this.props.customerConfig,
            previewSlotObj,
            this.state.userId,
            this.state.seedId,
            this.state.searchTerm
        ).then((response) => {
            if (response.ok) {
                response.json().then((responseJSON) => {
                    this.setState({
                        items: responseJSON.items,
                        previewLoading: false,
                        previewLoaded: true
                    });
                });
            }
        }).catch((error) => {
            this.setState({
                error
            });
        });
    }

    private renderSlotResults(): ReactNode {
        const { error, previewLoading: isLoading, previewLoaded: isLoaded } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <SearchResults
                customer={this.props.customerConfig}
                showMetadataLink={this.props.metadataAccess}
                items={this.state.items}
                username={this.props.username}
                groups={this.props.groups}
            />;
        }
    }

    private discardChanges = (): void => {
        if (this.state.create) {
            this.getSlots();
        } else {
            const slot = this.state.slots.filter((slot) => {
                return slot.slotId === this.state.slotId;
            })[0];

            const jsonDefinition = JSON.stringify(slot.experiments, undefined, 2);

            this.setState({
                slotExperiments: jsonDefinition
            });
        }
    }

    private setSlotExperiments = (value: string, event: any): void => {
        this.setState({
            slotExperiments: value
        });
    }

    private showSaveModal = (show: boolean): void => {
        this.setState({
            showSaveModal: show,
            slotSaving: true
        });
    }

    private closeModal = (show: boolean): void => {
        this.setState({
            showSaveModal: false,
            slotSaving: false
        });
    }

    private setSlotName = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotName: event.currentTarget.value
        });
    }

    private setSlotType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotType: event.target.value
        });
    }

    private setIsDefault = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotIsDefault: event.currentTarget.checked
        });
    }

    private setZeroResults= (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotZeroResultsOk: event.currentTarget.checked
        });
    }

    private generateSlotId = (): void => {
        this.setState({
            slotId: generateGuid()
        });
    }

    private changeSortOrder = (): void => {
        this.setState({
            sortOrder: this.state.sortOrder === "slotName" ? "slotId" : "slotName"
        });
    }

    private handleInvalidJsonModalClose = () => {
        this.setState({
            showInvalidJsonModal: false
        });
    };

    private handleInvalidJsonDataChange = (newJsonData: string) => {
        const { tempSlot } = this.state;

        const experiments = JSON.parse(newJsonData);
        const slotType = tempSlot!.type || "unknown";
        const jsonDefinition = JSON.stringify(experiments, undefined, 2);

        this.setState({
            slotId: tempSlot?.slotId || "",
            slot: tempSlot,
            slotName: tempSlot?.slotName || "",
            slotType,
            description: tempSlot!.description,
            slotExperiments: jsonDefinition,
            create: false
        }, () => {
            this.saveSlot();
        });
    };

    public render(): ReactNode {
        return (
            <Container className="mw-100">
                <SaveSlotModal
                    closeCallback={this.closeModal}
                    saveCallback={this.saveSlot}
                    showModal={this.state.showSaveModal}
                    slotId={this.state.slotId}
                    jsonData={this.state.slotExperiments}
                    slotName={this.state.slotName}
                    slotType={this.state.slotType}
                />
                <InvalidSlotModal
                    closeCallback={this.handleInvalidJsonModalClose}
                    onSave={this.handleInvalidJsonDataChange}
                    showModal={this.state.showInvalidJsonModal}
                    slotId={this.state.slotId}
                    jsonData={this.state.invalidJsonData}
                    slotName={this.state.slotName}
                    slotType={this.state.slotType}
                />
                <Row>
                    <EditorCol md={6}>
                        <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={10}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="slot-tooltip">Slot</Tooltip>}
                                        >
                                            <FieldIcon><PresetIcon /></FieldIcon>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="dropdown-slot"
                                        title="Slot"
                                        onChange={this.setSlot}
                                        style={{ "padding": "0.375rem", "height": "37px" }}
                                        value={this.state.slotId}
                                        size="sm"
                                        disabled={this.state.create}
                                    >
                                        {this.state.slots.sort((a, b) => {
                                            if (this.state.sortOrder === "slotId") {
                                                return a.slotId.localeCompare(b.slotId);
                                            } else {
                                                return a.slotName.localeCompare(b.slotName);
                                            }
                                        }).map((slot) => {
                                            if (this.state.sortOrder === "slotId") {
                                                return (
                                                    <option value={slot.slotId} key={slot.slotId}>
                                                        {slot.slotId} ({slot.slotName})
                                                    </option>
                                                );
                                            } else {
                                                return (
                                                    <option value={slot.slotId} key={slot.slotId}>
                                                        {slot.slotName} ({slot.slotId})
                                                    </option>
                                                );
                                            }
                                        })}
                                    </Form.Control>
                                    <InputGroup.Append>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="slot-tooltip">Sort Slots by {this.state.sortOrder === "slotName" ? "ID" : "name"}</Tooltip>}
                                        >
                                            <Button size="sm" onClick={this.changeSortOrder} >
                                                {this.state.sortOrder === "slotName" ?
                                                    <SortIdIcon /> : <SortNameIcon />
                                                }
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            <Col md={1} className="d-flex justify-content-end align-items-center ml-auto">
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Popover id="popover-basic">
                                        <Popover.Content>
                                            {this.state.slot?.dateCreated && <>
                                                <b>Created</b>: {this.state.slot?.dateCreated} by {this.state.slot?.createdBy}<br />
                                            </>}
                                            {this.state.slot?.dateUpdated && <>
                                                <b>Updated</b>: {this.state.slot?.dateUpdated} by {this.state.slot?.lastUpdatedBy}<br />
                                            </>}
                                        </Popover.Content>
                                    </Popover>}
                                >
                                    <Button size="sm" variant="secondary"><InfoIcon /></Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "5px", marginBottom: "5px", borderTop: "white solid thin", paddingTop: "5px" }}>
                            {this.state.slotSaving &&
                                <SpinnerDiv>
                                    <Spinner animation="border" variant="primary" />
                                </SpinnerDiv>
                            }

                            <Col md={6}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>ID</FieldName>
                                    </InputGroup.Prepend>
                                    <FieldValue
                                        id="slotIdInput"
                                        type="string"
                                        placeholder="Slot ID"
                                        value={this.state.slotId}
                                        autoComplete={"off"}
                                        size="sm"
                                        readOnly
                                    />
                                    <InputGroup.Append>
                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={<Tooltip id="refresh-tooltip">Generate New ID</Tooltip>}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                size="sm"
                                                onClick={() => { this.generateSlotId(); }}
                                                disabled={!this.state.create}
                                            >
                                                <RefreshIcon />
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Name</FieldName>
                                    </InputGroup.Prepend>
                                    <FieldValue
                                        id="slotNameInput"
                                        type="string"
                                        placeholder="Slot Name"
                                        value={this.state.slotName}
                                        onChange={this.setSlotName}
                                        autoComplete={"off"}
                                        size="sm"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={12}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Description</FieldName>
                                    </InputGroup.Prepend>
                                    <FieldValue
                                        id="slotNameInput"
                                        type="string"
                                        placeholder="Description"
                                        value={this.state.description}
                                        onChange={this.setDescription}
                                        autoComplete={"off"}
                                        size="sm"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={4} hidden> # Currently hidden as not active
                            <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Response Size</FieldName>
                                    </InputGroup.Prepend>
                                    <FieldValue
                                        id="slotNameInput"
                                        type="string"
                                        placeholder="Response Size"
                                        value={this.state.responseSize}
                                        onChange={this.setResponseSize}
                                        autoComplete={"off"}
                                        size="sm"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Type</FieldName>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="dropdown-slot"
                                        title="Type"
                                        onChange={this.setSlotType}
                                        style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                        value={this.state.slotType}
                                        size="sm"
                                    >
                                        {this.state.availableSlotTypes.map((slotType) => {
                                            return (
                                                <option value={slotType["key"]} key={slotType["key"]}>
                                                    {slotType["name"]}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={3} className="d-flex align-items-center">
                                <Form.Check
                                    type="checkbox"
                                    id="slot-isDefault"
                                    label="Default for type"
                                    checked={this.state.slotIsDefault}
                                    onChange={this.setIsDefault}
                                />
                            </Col>
                            <Col md={3} className="d-flex align-items-center">
                                <Form.Check
                                    type="checkbox"
                                    id="slot-isZeroResults"
                                    label="Zero Results OK?"
                                    checked={this.state.slotZeroResultsOk}
                                    onChange={this.setZeroResults}
                                />
                            </Col>
                            <Col md={2} className="d-flex justify-content-end">
                                <ButtonGroup>
                                    <EditorButton size="sm" title="Create New Slot" onClick={this.createNewSlot}>
                                        <CreateIcon />
                                    </EditorButton>
                                    <EditorButton size="sm" title="Save Slot" onClick={() => { this.showSaveModal(true); }}>
                                        <SaveIcon />
                                    </EditorButton>
                                    <EditorButton variant="secondary" title="Discard Changes" size="sm" onClick={this.discardChanges}>
                                        <ClearIcon />
                                    </EditorButton>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "5px", marginBottom: "5px", height: "66vh" }}>
                            <Col>
                                <AceEditor
                                    style={{ width: "100%", height: "100%" }}
                                    mode="json"
                                    theme="monokai"
                                    name="slotEditor"
                                    editorProps={{ $blockScrolling: true }}
                                    setOptions={{
                                        useSoftTabs: true,
                                        tabSize: 2
                                    }}
                                    value={this.state.slotExperiments}
                                    onChange={this.setSlotExperiments}
                                    wrapEnabled
                                />
                            </Col>
                        </Row>
                    </EditorCol>

                    <PreviewCol md={6}>
                        <PreviewFieldsRow>
                            <Col md={4} >
                                <RandomField
                                    customer={this.props.customerConfig}
                                    type={constants.USER}
                                    includeIcon
                                    small
                                    initialValue={this.state.userId}
                                    onChangeCallback={this.setUserId}
                                    onKeyUpCallback={this.checkKey}
                                />
                            </Col>
                            <Col md={4} style={{ marginRight: 0 }}>
                                <RandomField
                                    customer={this.props.customerConfig}
                                    type={constants.SEED}
                                    includeIcon
                                    small
                                    initialValue={this.state.seedId}
                                    onChangeCallback={this.setSeedId}
                                    onKeyUpCallback={this.checkKey}
                                    showName
                                />
                            </Col>
                            <Col md={3} style={{ marginRight: 0 }}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip id="search-tooltip">Search Term</Tooltip>}
                                        >
                                            <FieldIcon><SearchIcon /></FieldIcon>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>
                                    <FieldValue
                                        id="searchTermInput"
                                        type="string"
                                        placeholder="Search Term"
                                        onChange={this.setSearchTerm}
                                        value={this.state.searchTerm}
                                        onKeyUp={this.checkKey}
                                        autoComplete={"off"}
                                        size="sm"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={1} className="justify-content-end">
                                <OverlayTrigger
                                    overlay={<Tooltip id="preivew-tooltip">Preview</Tooltip>}
                                >
                                    <EditorButton size="sm" onClick={this.makeSlotPreviewRequest}>
                                        <PreviewIcon />
                                    </EditorButton>
                                </OverlayTrigger>
                            </Col>
                        </PreviewFieldsRow>
                        <Row>
                            <Col md>
                                {this.renderSlotResults()}
                            </Col>
                        </Row>
                    </PreviewCol>
                </Row>
            </Container>
        );
    }
}