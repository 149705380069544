import React, { ReactElement, ReactNode } from "react";
import { Alert, Container, Row, Card, Col, Form, Button, ButtonGroup, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
    FileEarmarkArrowDownFill as SaveIcon,
    Trash as DiscardIcon,
    ArrowLeft as ReturnIcon
} from "react-bootstrap-icons";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import * as customers from "../config/Customers";
import EntityForm from "../components/entity/EntityForm";
import { SearchResults } from "../components/SearchResults";
import Logger from "../utils/Logger";
import { entitySearch, createOrUpdateEntities, getAssociationsByEntityId, lookupItemsList } from "../utils/Requests";
import Branding from "../config/Branding";

const EntityFieldsCol = styled(Col)`
    border-radius: 3px;
    margin: 10px 0;
    position: fixed;
`;

const EntityInfoCol = styled(Col)`
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    background-color: ${Branding.superDarkBackground};
    padding: 0;
`;

const EntityInfoCard = styled(Card)`
    padding-top: 10px;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 38%;
    right: 45%;
    z-index: 10;
`;

const AssocAlert = styled(Alert)`
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

const ResultsSpinnerDiv = styled.div`
    position: absolute;
    top: 25%;
    right: 49%;
    z-index: 10;
`;

const AssociatedItemsHeader = styled(Row)`
    margin: 10px 0px 10px 0px;
`;

type EntityDetailState = {
    customerConfig: CustomerConfig
    currentEntity?: Entity,
    id: string,
    searchQuery: string,
    searchType: string,
    resultCount: string,
    loading: boolean,
    loaded: boolean,
    error?: Error | null,
    change: boolean,
    showAlert: boolean,
    refresh: boolean,
    items: Record<string, any>[],
}

type EntityDetailProps = {
    standalone?: boolean,
    groups: string[],
    username: string,
    setCustomerCallback: (customerName: string) => void
}

interface EntityDetailProperties extends EntityDetailProps, RouteComponentProps { }

class InvalidEntityError extends Error {

}

class EntityDetail extends React.Component<EntityDetailProperties, EntityDetailState> {

    private entityFormElement = React.createRef<EntityForm>();

    constructor(props: EntityDetailProperties) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);

        const id = urlParams.get("entityId") || "";
        const searchQuery = urlParams.get("searchQuery") || "";
        const searchType = urlParams.get("searchType") || "All";
        const resultCount = urlParams.get("resultCount") || "";
        const customerConfig = customers.getCustomerConfig(urlParams.get("customer"));

        this.state = {
            id, searchQuery, searchType, resultCount,customerConfig,
            loaded: false,
            loading: false,
            change: false,
            showAlert: false,
            refresh: false,
            items: []
        };
    }

    public componentDidMount(): void {
        this.requestEntity();
        this.requestEntityAssociations();

        this.props.setCustomerCallback(this.state.customerConfig.name);
    }

    private formChanged = (): void => {
        this.setState({
            change: true
        });
    }

    private async requestEntity(): Promise<void> {
        this.setState({
            loading: true
        });

        try {
            const { id } = this.state;
            const searchResults = await entitySearch("", id, undefined, 1);

            if (searchResults && searchResults.length >= 1) {
                const [entity] = searchResults;

                if (!entity.id) {
                    // How did this even happen
                    this.handleError(new InvalidEntityError());
                    return;
                }

                this.setState({
                    loading: false,
                    loaded: true,
                    currentEntity: entity
                });
            } else {
                this.setState({
                    loading: false,
                    loaded: false
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                this.handleError(error);
            }
        }
    }

    private async requestEntityAssociations(): Promise<void> {
        const { id, customerConfig } = this.state;

        this.setState({
            loading: true,
            showAlert: false
        });

        try {
            const response = await getAssociationsByEntityId(customerConfig, id);

            if (response.status !== 200) {
                if (response.status === 404) {
                    throw new Error("404: Not Found");
                } else {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        const errorJson = await response.json();
                        this.handleError(errorJson);
                    } else {
                        const errorText = await response.text();
                        this.handleError(new Error(errorText));
                    }
                }
                return;
            }

            const result = await response.json();

            if (result) {
                const itemsToLookup = result.map((item: Record<string, any>) => item["thingId"]);

                if (itemsToLookup.length === 0) {
                    this.setState({
                        items: [],
                        error: null,
                        loading: false
                    });
                    return;
                }

                const lookupResponse = await lookupItemsList(customerConfig, itemsToLookup.join(","));

                if (lookupResponse.ok) {
                    const lookupResult = await lookupResponse.json();
                    this.setState({
                        items: lookupResult["items"] || [],
                        error: null,
                        loading: false
                    });
                } else {
                    throw new Error(`Error ${lookupResponse.status}: ${lookupResponse.statusText}`);
                }
            } else {
                this.setState({
                    items: [],
                    error: null,
                    loading: false
                });
            }
        } catch (error) {
            this.handleError(error);
        }
    }

    private saveChanges = (): void => {
        const { currentEntity } = this.state;

        if (this.entityFormElement.current && currentEntity) {
            this.setState({
                refresh: true
            });

            const updatedEntity = this.entityFormElement.current.getEntity();
            const entityToUpdate: Array<Entity> = [{
                ...updatedEntity,
                id: currentEntity.id,
                dateCreated: currentEntity.dateCreated!,
                createdBy: currentEntity.createdBy!
            }];

            createOrUpdateEntities(entityToUpdate, true)
                .then(() => {
                    this.setState({
                        showAlert: true
                    });
                })
                .then(this.startRefresh)
                .catch((error: Error) => {
                    this.handleError(error);
                });
        }
    }

    private startRefresh = (): void => {
        setTimeout(() => {
            this.requestEntity();
        }, 1000);
        setTimeout(() => {
            this.setState({
                showAlert: false,
                refresh: false,
                error: null,
                change: false
            });
        }, 2500);
    }

    private discardChanges = (): void => {
        this.setState({
            change: false
        }, () => {
            if (this.state.currentEntity && this.entityFormElement.current) {
                this.entityFormElement.current.reset();
            }
        });
    }

    private closeAlert = (): void => {
        this.setState({
            showAlert: false
        });
    }

    private getAuditDetails = (): ReactElement | null => {
        const { id, currentEntity } = this.state;

        if (!currentEntity) {
            return null;
        }

        const { createdBy, dateCreated, updatedBy, dateUpdated } = currentEntity;

        return (
            <>
                ID: {id} <br />
                {
                    updatedBy &&
                    <div>Last updated by {updatedBy} at {String(dateUpdated!)} <br /></div>
                }
                {
                    createdBy &&
                    <div>Created by {createdBy} at {String(dateCreated!)}</div>
                }
            </>
        );
    }


    private goBack = () => {
        const { pathname } = this.props.location;
        const { searchQuery, searchType, resultCount } = this.state;

        this.props.history.push("/entities", {
            from: pathname,
            name: searchQuery,
            type: searchType,
            count: resultCount
        } as { from: any, name: string, type: string, count: string });
    }

    public render(): ReactNode {
        const { username, groups } = this.props;
        const { customerConfig, error, showAlert, refresh, change, currentEntity, loading, items } = this.state;

        return (
            <>
                <Container className="mw-100" key="metadata-container">
                    <Row>
                        <EntityFieldsCol md={3} className="mx-auto">
                            <AssocAlert
                                variant={error ? "danger" : "success"}
                                show={showAlert}
                                onClose={this.closeAlert}
                                dismissible
                            >
                                {error ? error.message : "Entity updates saved"}
                            </AssocAlert>
                            {refresh && <SpinnerDiv>
                                <Spinner animation="border" style={{ color: "white" }} />
                            </SpinnerDiv>}
                            <div style={{ opacity: refresh ? 0.5 : 1 }}>
                                <EntityInfoCard>
                                    <Form>
                                        <Card.Header style={{ padding: "0.25rem 0.75rem", fontSize: "1.5rem", color: "white" }}>
                                            <Button
                                                variant="primary"
                                                onClick={this.goBack}
                                                style={{ marginRight: "5%" }}
                                            >
                                                <ReturnIcon size={22} />
                                            </Button>
                                            Entity Detail
                                        </Card.Header>
                                        <Card.Body style={{ padding: "0.25rem 0.75rem" }}>
                                            <EntityForm
                                                ref={this.entityFormElement}
                                                entity={currentEntity}
                                                changeCallback={this.formChanged}
                                            />
                                        </Card.Body>
                                        <Card.Footer style={{ padding: "0.25rem 0.75rem" }}>
                                            <Row>
                                                <Col className="d-flex justify-content-end" >
                                                    <ButtonGroup>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip id="save-tooltip">Save Changes</Tooltip>}>
                                                            <Button
                                                                variant="primary"
                                                                onClick={this.saveChanges}
                                                                disabled={!change}
                                                            >
                                                                <SaveIcon size={22} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip id="save-tooltip">Discard Changes</Tooltip>}>
                                                            <Button
                                                                variant="secondary"
                                                                onClick={this.discardChanges}
                                                                disabled={!change}
                                                            >
                                                                <DiscardIcon size={22} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {this.getAuditDetails()}
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </Form>
                                </EntityInfoCard>
                            </div>
                        </EntityFieldsCol>
                        <EntityInfoCol md={9} className="justify-content-center">
                            {loading && <ResultsSpinnerDiv>
                                <Spinner animation="border" style={{ color: "white" }} />
                            </ResultsSpinnerDiv>}
                            <Card.Header style={{ padding: "0.25rem 0.75rem", fontSize: "1.5rem", color: "white" }}>
                                <AssociatedItemsHeader>
                                    <Col md={6}>Associated Items</Col>
                                </AssociatedItemsHeader>
                            </Card.Header>
                            <div style={{ opacity: loading ? 0.5 : 1, overflow: "auto", height: "85vh" }}>
                                <Card.Body>
                                    <SearchResults
                                        customer={customerConfig}
                                        showMetadataLink={true}
                                        items={items}
                                        username={username}
                                        groups={groups}
                                    />
                                </Card.Body>
                            </div>
                        </EntityInfoCol>
                    </Row>
                </Container>
            </>
        );
    }

    private handleError(error: unknown): void {
        if (error instanceof Error) {
            Logger.error(error);
            this.setState({
                loading: false,
                loaded: true,
                error,
                showAlert: true
            });
        } else {
            const unknownError = new Error("An unknown error occurred");
            Logger.error(unknownError);
            this.setState({
                loading: false,
                loaded: true,
                error: unknownError,
                showAlert: true
            });
        }
    }
}

export default withRouter(EntityDetail);