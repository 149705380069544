import React, { ReactNode } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import styled from "styled-components";

import { entityTypeaheadSearch } from "../../utils/Requests";
import Branding from "../../config/Branding";

const EntityTypeaheadContainer = styled.div`
    text-align: left;
    background-color: ${Branding.white};;
    border-radius: 3px;
`;

type EntityTypeaheadState = {
    loading: boolean,
    error: boolean,
    entityAssociations: EntityAssociation[],
    searchResults: EntityAssociation[],
    errorMsg?: string
}

type EntityTypeaheadProperties = {
    customer: string,
    entityType: string,
    existingEntityAssociations: EntityAssociation[],
    enabled: boolean
}

export default class EntityTypeahead extends React.Component<EntityTypeaheadProperties, EntityTypeaheadState> {

    constructor(props: EntityTypeaheadProperties) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            entityAssociations: props.existingEntityAssociations,
            searchResults: []
        };
    }

    public render(): ReactNode {
        return (
            <EntityTypeaheadContainer>
                {this.getTypeahead()}
            </EntityTypeaheadContainer>
        );
    }

    public getAssociations(): EntityAssociation[] {
        return this.state.entityAssociations;
    }

    private getTypeahead(): ReactNode {
        // Bypass client-side filtering by returning `true`. Results are already
        // filtered by the search endpoint, so no need to do it again.
        const filterBy = () => true;

        return (
            <AsyncTypeahead
                id={"basic-typeahead-multiple"}
                labelKey={"entityName"}
                multiple
                filterBy={filterBy}
                isLoading={this.state.loading}
                onSearch={this.handleSearch}
                options={this.state.searchResults}
                onChange={(selected: Option[]) => { this.handleChange(selected as EntityAssociation[]); }}
                selected={this.state.entityAssociations}
                minLength={2}
                disabled={!this.props.enabled}
                allowNew
            />
        );
    }

    private handleChange = (selected: EntityAssociation[]): void => {
        selected.forEach(entity => {
            if (entity.customOption) {
                entity.entityType = this.props.entityType;
            }
        });
        this.setState(
            {
                entityAssociations: selected
            }
        );
    }

    private handleSearch = async (query: string): Promise<void> => {

        this.setState(
            {
                loading: true
            }
        );

        const searchResults = await entityTypeaheadSearch(this.props.entityType, query);

        this.setState({
            // Limit to top 9 results, so the 10th can be 'allow new'
            searchResults: searchResults.slice(0, 9),
            loading: false
        });
    }
}