import React, { ReactNode, Component } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";

import PromotionModal from "../components/promotions/PromotionModal";
import PromotionCard from "../components/promotions/PromotionCard";

import * as customers from "../config/Customers";
import { getPromotions, getSlotsForCustomer } from "../utils/Requests";
import Branding from "../config/Branding";

const AddPromotionsCol = styled(Col)`
    background: ${Branding.superDarkBackground};
    margin-top: 10px;
    border-radius: 3px;
    padding: 5px;
`;

enum ModalType {
    None,
    Add,
    Edit,
    Duplicate,
}

type PromotionsState = {
    customerConfig: CustomerConfig,
    promotions: Record<string, any>[],
    slots: Slot[],
    promotionLoading: boolean,
    slotLoading: boolean,
    error: any,
    showAddPromotionModal: boolean,
    showEditPromotionModal: boolean,
    showDuplicatePromotionModal: boolean,
    selectedPromotion: Record<string, any>,
    selectedPromotionItems: Record<string, any>[],
    modalType: ModalType;
}

type PromotionsProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig,
    setCustomerCallback: (customerName: string) => void
}

class Promotions extends Component<PromotionsProperties, PromotionsState> {

    constructor(props: PromotionsProperties) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);

        const customerName = urlParams.get("customer");
        let customerConfig;

        if (customerName) {
            customerConfig = customers.getCustomerConfig(customerName);
            this.props.setCustomerCallback(customerName);
        }

        this.state = {
            customerConfig: customerConfig || this.props.customerConfig,
            promotions: [],
            slots: [],
            promotionLoading: true,
            slotLoading: true,
            error: null,
            showAddPromotionModal: false,
            showEditPromotionModal: false,
            showDuplicatePromotionModal: false,
            selectedPromotion: {},
            selectedPromotionItems: [],
            modalType: ModalType.None
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.getSlots();
        this.getPromotions();
    }

    private async getSlots(): Promise<void> {
        this.setState({
            slotLoading: true
        });
        await getSlotsForCustomer(this.state.customerConfig).then((slots: Array<Slot>) => {
            this.setState({
                slots,
                slotLoading: false
            });
        });
    }

    private async getPromotions(): Promise<void> {
        this.setState({
            promotionLoading: true
        });

        try {
            const availablePromotions = await getPromotions(this.state.customerConfig);

            this.setState({
                promotions: availablePromotions,
                promotionLoading: false,
                error: null
            });
        } catch (error) {
            this.setState({
                error,
                promotions: [],
                promotionLoading: false
            });
        }
    }

    private renderPromotions(): ReactNode {
        const { promotionLoading, promotions, slots, error } = this.state;
        const { customerConfig, username, groups } = this.props;

        if (promotionLoading) {
            return (
                <Row className="justify-content-center" style={{ marginTop: "20px" }}>
                    <Spinner animation={"border"} variant={"primary"} />
                </Row>
            );
        }

        if (promotions && promotions.length > 0) {
            return promotions.map(promotion => (
                <Row key={`${promotion.promotionId}-card`} className="justify-content-center">
                    <Col md={10} style={{ background: Branding.darkBackground, paddingTop: "10px", paddingBottom: "10px", borderTop: "white thin solid" }}>
                        <PromotionCard
                            readOnly
                            customerConfig={customerConfig}
                            promotion={promotion}
                            slots={slots}
                            username={username}
                            groups={groups}
                            editCallback={this.editCallback}
                            duplicateCallback={this.duplicateCallback}
                            deleteCallback={this.deleteCallback}
                        />
                    </Col>
                </Row>
            ));
        } else if (error) {
            return (
                <Row className="justify-content-center">
                    <Col md={10} style={{ textAlign: "center", paddingTop: "10px" }}>
                        <h5>{error.name} - {error.message}</h5>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="justify-content-center">
                    <Col md={10} style={{ textAlign: "center", paddingTop: "10px" }}>
                        <h5>No existing promotions for this customer</h5>
                    </Col>
                </Row>
            );
        }
    }

    private showModal(modalType: ModalType) {
        this.setState({ modalType });

        if (modalType === ModalType.None) {
            this.getPromotions();
        }
    }

    private editCallback = (promotion: Record<string, any>, items: Record<string, any>[]) => {
        this.setState({
            selectedPromotion: promotion,
            selectedPromotionItems: items
        });
        this.showModal(ModalType.Edit);
    };

    private duplicateCallback = (promotion: Record<string, any>, items: Record<string, any>[]) => {
        this.setState({
            selectedPromotion: promotion,
            selectedPromotionItems: items
        });
        this.showModal(ModalType.Duplicate);
    };

    private deleteCallback = () => {
        this.getPromotions();
    }

    public render(): ReactNode {
        const { modalType, slots, selectedPromotion, selectedPromotionItems } = this.state;
        const { customerConfig, username, groups } = this.props;

        return (
            <>
                <Container className="mw-100" key="promotions">
                    <PromotionModal
                        customer={customerConfig}
                        showModal={modalType === ModalType.Add}
                        closeCallback={() => {
                            this.showModal(ModalType.None);
                        }}
                        username={username}
                        groups={groups}
                        slots={slots}
                        edit={false}
                    />
                    {modalType === ModalType.Edit && (
                        <PromotionModal
                            customer={customerConfig}
                            showModal={modalType === ModalType.Edit}
                            closeCallback={() => {
                                this.showModal(ModalType.None);
                            }}
                            username={username}
                            groups={groups}
                            slots={slots}
                            edit={true}
                            selectedPromotion={selectedPromotion}
                            selectedPromotionItems={selectedPromotionItems}
                        />
                    )}
                    {modalType === ModalType.Duplicate && (
                        <PromotionModal
                            customer={customerConfig}
                            showModal={modalType === ModalType.Duplicate}
                            closeCallback={() => {
                                this.showModal(ModalType.None);
                            }}
                            username={username}
                            groups={groups}
                            slots={slots}
                            edit={false}
                            duplicate={true}
                            selectedPromotion={selectedPromotion}
                            selectedPromotionItems={selectedPromotionItems}
                        />
                    )}
                    <Row className="justify-content-center">
                        <AddPromotionsCol md={2} className="d-flex justify-content-start">
                            <h4>Promotions</h4>
                        </AddPromotionsCol>
                        <AddPromotionsCol md={8} className="d-flex justify-content-end">
                            <Button onClick={() => { this.showModal(ModalType.Add); }}>Add Promotion</Button>
                        </AddPromotionsCol>
                    </Row>
                    {this.renderPromotions()}
                </Container>
            </>
        );
    }
}

export default Promotions;