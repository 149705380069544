import React, { ReactElement, ReactNode } from "react";
import { Badge, Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Branding from "../config/Branding";
import { getHealthDomainInformation, getApiHealthInformation, getHealthDomainTopicInformation } from "../utils/Requests";
import {
    ShiftFill as Arrow,
    ArrowClockwise as RefreshIcon,
    InfoCircleFill as InfoIcon
} from "react-bootstrap-icons";
import moment from "moment";
import Logger from "../utils/Logger";

const MainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
    margin: 10px;
`;

const ApiHealthRow = styled(Row)`
    background: ${Branding.secondary};
    border-radius: 10px;
    padding: 5px;
    margin: 10px 0 10px 0;
    text-align: middle;
    line-height: 2.4;
`;

const HealthBadge = styled(Badge)`
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
`;

const ApiHealthLabel = styled.span`
    color: ${Branding.white};
    font-size: 16px;
`;

const AcrossTriangle = styled(Arrow)`
    transform: rotate(90deg);
    float: right;
`;

const UpTriangle = styled(Arrow)`
    transform: rotate(0deg);
    float: right;
`;

const DiagonalUpTriangle = styled(Arrow)`
    transform: rotate(45deg);
    float: right;
`;

const DownTriangle = styled(Arrow)`
    transform: rotate(180deg);
    float: right;
`;

const DiagonalDownTriangle = styled(Arrow)`
    transform: rotate(135deg);
    float: right;
`;

type SystemHealthState = {
    apiHealth: string,
    apiHealthValues: Array<Record<string, any>>,
    jobHealth: Array<Record<string, any>>,
    totalEventCounts: Record<string, any>,
    platformEventCounts: Record<string, any>,
    actionEventCounts: Record<string, any>,
    personalisationEventCounts: Record<string, any>,
    metadataHealth: Array<Record<string, any>>,
    lastRefreshTime: string,
    lastUpdated: string
}

type SystemHealthProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    metadataAccess?: boolean,
    customerConfig: CustomerConfig
}

export default class SystemHealth extends React.Component<SystemHealthProperties, SystemHealthState> {

    private timerID: NodeJS.Timer | undefined;

    constructor(props: SystemHealthProperties) {
        super(props);

        this.state = {
            apiHealth: "Healthy",
            apiHealthValues: [],
            jobHealth: [],
            totalEventCounts: {},
            platformEventCounts: {},
            actionEventCounts: {},
            personalisationEventCounts: {},
            metadataHealth: [],
            lastRefreshTime: "",
            lastUpdated: ""
        };
    }

    public componentDidMount(): void {
        if (this.state.jobHealth.length === 0) {
            this.refresh();
            this.timerID = setInterval(
                () => this.updateLastUpdated(this.state.lastRefreshTime),
                30000
            );
        }
    }

    public componentWillUnmount(): void {
        clearInterval(this.timerID);
    }

    private refresh = (): void => {
        this.setState({
            apiHealth: "Healthy",
            apiHealthValues: [],
            jobHealth: [],
            totalEventCounts: {},
            platformEventCounts: {},
            actionEventCounts: {},
            personalisationEventCounts: {},
            metadataHealth: [],
            lastRefreshTime: "",
            lastUpdated: ""
        });

        const lastRefreshTime = moment().format();
        this.getApiHealth();
        this.getJobHealth();
        this.getEventHealth();
        this.getMetadataHealth();
        this.setState({
            lastRefreshTime,
            lastUpdated: moment(lastRefreshTime).fromNow()
        });
    }

    private updateLastUpdated = (lastRefreshTime: string): void => {
        this.setState({
            lastUpdated: moment(lastRefreshTime).fromNow()
        });
    }

    private async getApiHealth(): Promise<void> {
        await getApiHealthInformation(this.props.customerConfig).then((response: Record<string, any>) => {
            const metrics = response["metrics"][0]["values"];
            const latestMetric = metrics[0]["value"];
            let healthValue = "Healthy";

            if (95 <= latestMetric && latestMetric < 98.5) {
                healthValue = "Warning";
            } else if (latestMetric < 95) {
                healthValue = "Error";
            }

            this.setState({
                apiHealth: healthValue,
                apiHealthValues: metrics
            });
        }).catch(error => {
            Logger.error("Error getting API health: ", error);
        });
    }

    private async getJobHealth(): Promise<void> {
        await getHealthDomainInformation(this.props.customerConfig, "job_health").then((response: Array<Record<string, any>>) => {
            response.forEach((job) => {
                // Remove 'Standard' from job name for display purposes
                job["topic"] = job["topic"].replace("Standard ", "");
            });
            this.setState({
                jobHealth: response
            });
        }).catch(error => {
            Logger.error("Error getting Job health: ", error);
        });
    }

    private async getEventHealth(): Promise<void> {
        await getHealthDomainInformation(this.props.customerConfig, "events").then((response: Array<Record<string, any>>) => {
            let totalEventCounts = {}, platformEventCounts = {}, actionEventCounts = {}, personalisationEventCounts = {};
            response.forEach((element: Record<string, any>) => {
                if (element["topic"] === "platform") {
                    platformEventCounts = element;
                }
                else if (element["topic"] === "total_event_counts") {
                    totalEventCounts = element;
                }
                if (element["topic"] === "personalisation") {
                    personalisationEventCounts = element;
                }
                if (element["topic"] === "action") {
                    actionEventCounts = element;
                }
            });

            this.setState({
                totalEventCounts,
                platformEventCounts,
                actionEventCounts,
                personalisationEventCounts
            });
        }).catch(error => {
            Logger.error("Error getting event health: ", error);
        });
    }

    private async getMetadataHealth(): Promise<void> {
        await getHealthDomainTopicInformation(this.props.customerConfig, "catalogue", "detailed_asset_counts").then((response: Array<Record<string, any>>) => {
            this.setState({
                metadataHealth: response
            });
        }).catch(error => {
            Logger.error("Error getting metadata health: ", error);
        });
    }

    private getHealthBadge(): ReactElement {
        switch (this.state.apiHealth) {
            case "Warning":
                return <HealthBadge variant="warning">Warning</HealthBadge>;
            case "Error":
                return <HealthBadge variant="danger">Error</HealthBadge>;
            default:
                return <HealthBadge variant="success">Healthy</HealthBadge>;
        }
    }

    private populateJobHealth(): ReactNode {
        return this.state.jobHealth.sort(
            (a: Record<string, any>, b: Record<string, any>) => {
                return a["topic"].localeCompare(b["topic"]);
            }
        ).map(job => {
            return <tr id={job["topic"]}>
                <td>{job["topic"]}</td>
                <td>{job["value"]["status"]}</td>
                <td>{job["value"]["last_run"] !== "Unknown" ? moment(job["value"]["last_run"]).calendar() : "Unknown"}</td>
                <td>{job["value"]["next_run"] !== "Unknown" ? moment(job["value"]["next_run"]).calendar() : "Unknown"}</td>
            </tr>;
        });
    }

    private getEventArrow(percentageChange: string): ReactNode {
        const percentageNumeric = parseFloat(percentageChange);

        if (percentageNumeric >= 15) {
            return <UpTriangle color="green" />;
        } else if (percentageNumeric >= 5) {
            return <DiagonalUpTriangle color="green" />;
        } else if (percentageNumeric <= -15) {
            return <DownTriangle color="red" />;
        } else if (percentageNumeric <= -5) {
            return <DiagonalDownTriangle color="red" />;
        } else {
            return <AcrossTriangle />;
        }
    }

    private populateEventHealth(): ReactNode {
        if (this.state.totalEventCounts["value"]) {
            const eventCounts = this.state.totalEventCounts["value"];
            return <tr id="event_health_all">
                <td>All</td>
                <td>{eventCounts["1d_events"]} {this.getEventArrow(eventCounts["1d_change"])}</td>
                <td>{eventCounts["7d_events"]} {this.getEventArrow(eventCounts["7d_change"])}</td>
                <td>{eventCounts["30d_events"]} {this.getEventArrow(eventCounts["30d_change"])}</td>
            </tr>;
        }
    }

    private populateActionEvents(): ReactNode {
        const actionsRows: Array<ReactNode> = [];
        if (this.state.actionEventCounts["value"]) {
            const eventCounts = this.state.actionEventCounts["value"];

            Object.keys(eventCounts).sort().forEach((actionKey: string) => {
                const values = eventCounts[actionKey];
                actionsRows.push(
                <tr id={`event_health_${actionKey}`}>
                    <td>{actionKey}</td>
                    <td>{values["1d_events"]} {this.getEventArrow(values["1d_change"])}</td>
                    <td>{values["7d_events"]} {this.getEventArrow(values["7d_change"])}</td>
                    <td>{values["30d_events"]} {this.getEventArrow(values["30d_change"])}</td>
                </tr>);
            });
        }

        return <>{actionsRows}</>;
    }

    private populatePlatformCounts(): ReactNode {
        const platformRows: Array<ReactNode> = [];
        if (this.state.platformEventCounts["value"]) {
            const eventCounts = this.state.platformEventCounts["value"];

            Object.keys(eventCounts).sort().forEach((platformKey: string) => {
                const values = eventCounts[platformKey];
                platformRows.push(
                <tr id={`event_health_${platformKey}`}>
                    <td>{platformKey}</td>
                    <td>{values["1d_events"]} {this.getEventArrow(values["1d_change"])}</td>
                    <td>{values["7d_events"]} {this.getEventArrow(values["7d_change"])}</td>
                    <td>{values["30d_events"]} {this.getEventArrow(values["30d_change"])}</td>
                </tr>);
            });
        }

        return <>{platformRows}</>;
    }

    private populatePersonalisationHealth(): ReactNode {
        const persoRows: Array<ReactNode> = [];
        if (this.state.personalisationEventCounts["value"]) {
            const persoCounts = this.state.personalisationEventCounts["value"].sort(
                (a: Record<string, any>, b: Record<string, any>) => {
                    return a["slot_name"].localeCompare(b["slot_name"]);
                }
            );

            persoCounts.forEach((persoRecord: Record<string, any>) => {
                persoRows.push(
                <tr id={`personalisation_${persoRecord["slot_id"]}`}>
                    <td>{persoRecord["slot_name"]}</td>
                    <td>{persoRecord["1d_total_requests"]}</td>
                    <td>{persoRecord["1d_distinct_users"]}</td>
                    <td>{persoRecord["1d_zero_results"] ?? 0}</td>
                    <td>{persoRecord["7d_total_requests"]}</td>
                    <td>{persoRecord["7d_distinct_users"]}</td>
                    <td>{persoRecord["7d_zero_results"] ?? 0}</td>
                    <td>{persoRecord["30d_total_requests"]}</td>
                    <td>{persoRecord["30d_distinct_users"]}</td>
                    <td>{persoRecord["30d_zero_results"] ?? 0}</td>
                </tr>);
            });
        }

        return <>{persoRows}</>;
    }

    private populateMetadataHealth(): ReactNode {
        const metadataRows: Array<ReactNode> = [];
        if (this.state.metadataHealth) {
            const metadataHealth = this.state.metadataHealth.sort(
                (a: Record<string, any>, b: Record<string, any>) => {
                    return a["type"].localeCompare(b["type"]);
                }
            );

            metadataHealth.forEach((metadataRecord: Record<string, any>) => {
                metadataRows.push(
                <tr id={`metadata_${metadataRecord["type"]}`}>
                    <td>{metadataRecord["type"]}</td>
                    <td>{metadataRecord["active"]}</td>
                    <td>{metadataRecord["inactive"]}</td>
                    <td>{metadataRecord["recently_added"]}</td>
                    <td>{metadataRecord["total"]}</td>
                </tr>);
            });
        }

        return <>{metadataRows}</>;
    }

    public render(): ReactNode {
        return (
            <div>
                <Container className="mw-100" key="central">
                    <MainRow className="justify-content-center sticky-banner" standalone={this.props.standalone ? 1 : 0}>
                        <Col md>
                            <ApiHealthRow>
                                <Col md={7}>
                                    <ApiHealthLabel className="align-middle">
                                        <b>Overall API Health:</b>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip id="customer-tooltip">{this.state.apiHealthValues.length > 0 && `${this.state.apiHealthValues[0]["value"]}% 2xx responses`}</Tooltip>}
                                        >
                                            {this.getHealthBadge()}
                                        </OverlayTrigger>
                                    </ApiHealthLabel>
                                </Col>
                                <Col md={5} className="d-flex justify-content-end" style={{lineHeight: "2.8"}}>
                                    Last Updated: {this.state.lastUpdated}<Button size="sm" style={{marginLeft: "5px", width: "45px"}} onClick={this.refresh}><RefreshIcon size={18}/></Button>
                                </Col>
                            </ApiHealthRow>
                            <Row>
                                <Col md={6} style={{textAlign: "center", padding: "5px"}}>
                                    <h5>Job Health</h5>
                                    <Table striped bordered responsive size="sm">
                                        <thead>
                                            <tr id="job_health_title">
                                                <th>Job Name</th>
                                                <th>Status</th>
                                                <th>Last Run</th>
                                                <th>Next Run</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.jobHealth.length > 0 && this.populateJobHealth()}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={6} style={{textAlign: "center", padding: "5px"}}>
                                    <h5>Metadata</h5>
                                    <Table striped bordered responsive size="sm">
                                        <thead>
                                            <tr id="metadata_health_title">
                                                <th>Type</th>
                                                <th>Active</th>
                                                <th>Inactive</th>
                                                <th>Recently Added</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.metadataHealth.length > 0 && this.populateMetadataHealth()}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} style={{textAlign: "center", padding: "5px"}}>
                                    <h5>
                                        Events
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                            <Table style={{zIndex: 9000, background: "black", width: "340px", border: "grey solid"}}>
                                                <tr id="event_key_up">
                                                    <td><UpTriangle color="green" style={{float: "left", marginRight: "10px"}}/> Significant increase over previous period</td>
                                                </tr>
                                                <tr id="event_key_diag_up">
                                                    <td><DiagonalUpTriangle color="green" style={{float: "left", marginRight: "10px"}}/> Minor increase over previous period</td>
                                                </tr>
                                                <tr id="event_key_across">
                                                    <td><AcrossTriangle color="white" style={{float: "left", marginRight: "10px"}}/> Minimal change over previous period</td>
                                                </tr>
                                                <tr id="event_key_diag_down">
                                                    <td><DiagonalDownTriangle color="red" style={{float: "left", marginRight: "10px"}}/> Minor decrease over previous period</td>
                                                </tr>
                                                <tr id="event_key_down">
                                                    <td><DownTriangle color="red" style={{float: "left", marginRight: "10px"}}/> Significant decrease over previous period</td>
                                                </tr>
                                            </Table>}
                                        >
                                            <InfoIcon size={16} style={{marginLeft: "5px", verticalAlign: "top" }}/>
                                        </OverlayTrigger>
                                    </h5>

                                    <Table striped bordered responsive size="sm">
                                        <thead>
                                            <tr id="event_health_title">
                                                <th>Type</th>
                                                <th>1 day</th>
                                                <th>7 days</th>
                                                <th>30 days</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.totalEventCounts && this.populateEventHealth()}
                                        </tbody>
                                        {this.state.actionEventCounts &&
                                            <>
                                            <thead>
                                            <tr id="event_health_actions">
                                                    <th colSpan={4}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.populateActionEvents()}
                                            </tbody>
                                            </>
                                        }
                                        {this.state.actionEventCounts &&
                                            <>
                                            <thead>
                                                <tr id="event_health_platforms">
                                                    <th colSpan={4}>Platforms</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.populatePlatformCounts()}
                                            </tbody>
                                            </>
                                        }
                                    </Table>
                                </Col>
                                <Col md={7} style={{textAlign: "center", padding: "5px"}}>
                                    <h5>Personalisation</h5>
                                    <Table striped bordered responsive size="sm">
                                        <thead>
                                            <tr id="perso_health_title">
                                                <th rowSpan={2}>Slot</th>
                                                <th colSpan={3}>1 day</th>
                                                <th colSpan={3}>7 days</th>
                                                <th colSpan={3}>30 days</th>
                                            </tr>
                                            <tr id="perso_health_subtitle">
                                                <th>Requests</th>
                                                <th>Users</th>
                                                <th>Zero Results</th>
                                                <th>Requests</th>
                                                <th>Users</th>
                                                <th>Zero Results</th>
                                                <th>Requests</th>
                                                <th>Users</th>
                                                <th>Zero Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.personalisationEventCounts && this.populatePersonalisationHealth()}
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>
                        </Col>
                    </MainRow>
                </Container>
            </div>
        );
    }
}