import * as placeholders from "../images/Placeholders";
import Logger from "../utils/Logger";
import placeholder from "../images/placeholder.png";
import React, { ReactNode, SyntheticEvent } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { UserClusterTypes } from "../enums";
import { BUILD_ENVIRONMENT, DATE_FORMAT } from "../constants";
import styled from "styled-components";
import Branding from "../config/Branding";

export function convertToTitle(str: string): string {
    return str.replaceAll("-", " ").replaceAll(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
      );
}

export function sortStringArrayNumerically(stringArray: Array<string>): Array<string> {
    return stringArray.sort(function(a, b) {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base"
        });
      }
    );
}

export function isEmpty(str: string | undefined): boolean {
    return (!str || str.length === 0);
}

export function shuffle(array: Array<any>): Array<any> {
    let currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

export function seedIdCheck(customer: string, seedId: string): string {
    let customerSeedId = seedId;

    if (customer.toLowerCase() === "uktv" && seedId.indexOf("_brand") < 0) {
        customerSeedId = seedId + "_brand";
    }

    return customerSeedId;
}

export function getGenres(item: Record<string, any>): Array<string> {
    let genres = [];
    let categories = [];
    if (item.hasOwnProperty("custom")) {
        const custom = item["custom"];
        if (custom.hasOwnProperty("category")) {
            categories = item["custom"]["category"];
        }
    }

    if (item.hasOwnProperty("genre")) {
        genres = item["genre"];
    }

    if (genres.length === 0 && categories.length > 0) {
        genres = categories;
    }

    if (typeof genres === "string") {
        return [genres];
    } else {
        return genres.map((genre: any, i: number) => {
            return genre.name;
        });
    }
}

export function getSubGenres(item: Record<string, any>): Array<string> {
    let subgenres = [];
    let subcategories = [];
    if (item.hasOwnProperty("custom")) {
        const custom = item["custom"];
        if (custom.hasOwnProperty("subCategory")) {
            subcategories = item["custom"]["subCategory"];
        }
    }

    if (item.hasOwnProperty("subGenre")) {
        subgenres = item["subGenre"];
    }

    if (subgenres.length === 0 && subcategories.length > 0) {
        subgenres = subcategories;
    }

    if (typeof subgenres === "string") {
        return [subgenres];
    } else {
        return subgenres.map((subgenre: any, i: number) => {
            return subgenre.name;
        });
    }
}

export function getActors(actors: Array<any>): string[] {
    if (actors !== undefined) {
        return actors.map((actor, i) => {
            return actor.name;
        }).slice(0, 5);
    } else {
        return [];
    }
}

export function getAllActors(actors: Array<any>): string[] {
    if (actors !== undefined) {
        return actors.map((actor, i) => {
            return actor.name;
        });
    } else {
        return [];
    }
}

export function getAllProducers(producers: Array<any>): string[] {
    if (producers !== undefined) {
        return producers.map((producer, i) => {
            return producer.name;
        });
    } else {
        return [];
    }
}

export function getDirector(directors: Array<any>): string[] {
    if (directors !== undefined) {
        if (typeof directors === "string") {
            return [directors];
        } else {
            return directors.map((director: any, i: number) => {
                return director.name;
            });
        }
    } else {
        return [];
    }
}

export function getContentRating(item: Record<string, any>): string {
    if (item !== undefined) {
        if (item.hasOwnProperty("contentRating")) {
            const contentRatingObj = item["contentRating"];
            if (contentRatingObj.length > 0) {
                if (contentRatingObj[0].hasOwnProperty("ratingValue")) {
                    return contentRatingObj[0]["ratingValue"];
                } else if (contentRatingObj[0].hasOwnProperty("custom")) {
                    return contentRatingObj[0]["custom"]["rawRatingValue"];
                } else if (contentRatingObj[0].hasOwnProperty("name")) {
                    return contentRatingObj[0]["name"];
                }
            }
        }
    }
    return "Unknown";
}

export function getDatePublished(item: Record<string, any>): string {
    if (item["datePublished"]) {
        return item["datePublished"].slice(0, 4);
    } else if (item["custom"]) {
        if (item["custom"]["releaseYear"]) {
            return item["custom"]["releaseYear"];
        }
    }

    return "Unknown";
}

export function getVideoQuality(item: Record<string, any>): string | undefined {
    if (item["video"]) {
        return item["video"]["videoQuality"];
    }
}

export function getSchedulerChannel(item: Record<string, any>): string | undefined {
    if (item["custom"]) {
        if (item["custom"]["schedulerChannel"]) {
            return item["custom"]["schedulerChannel"][0];
        }
    }
}

export function getFlavourText(value: Record<string, any>): ReactNode | void {
    // Hacky af, like a lot of this. But, ya know. It's a start!
    const schedulerChannel = value?.["custom"]?.["schedulerChannel"]?.[0];
    if (!isEmptyOrSpaces(schedulerChannel)) {
        return (<><b>Scheduler Channel</b>: {schedulerChannel}</>);
    }

    const brandName = value?.["custom"]?.["brand"]?.[0]?.["name"];
    if (!isEmptyOrSpaces(brandName)) {
        return (<><b>Brand</b>: {schedulerChannel}</>);
    }
}

export function isEmptyOrSpaces(value: string): boolean {
    return value === undefined || value === null || value.match(/^ *$/) !== null;
}

export function getImageUrl(result: Record<string, any>): string {
    const imageUrl = result?.["imageUrl"];
    const imageDotUrl = result?.["image"]?.["url"];

    try {
        if (!isEmptyOrSpaces(imageUrl)) {
            return imageUrl;
        }

        if (!isEmptyOrSpaces(imageDotUrl)) {
            return imageDotUrl;
        }

        if (typeof result["image"] === "object") {
            const imageLink = result["image"][0]["url"];
            if (!isEmptyOrSpaces(imageLink)) {
                return imageLink;
            }
        }

        if (result["id"] !== undefined) {
            const splitId: string[] = result["id"].split("/");
            let id: string = splitId[splitId.length - 1];

            if (result["id"].indexOf("magnetmedia.be") > -1 || result["id"].indexOf("sbsbelgium.be") > -1 ||
                result["id"].indexOf("telenet.be") > -1 || result["id"].indexOf("vrt.be") > -1) {
                return `https://portal.${BUILD_ENVIRONMENT}.thefilter.com/images/telenet/${id}.jpg`;
            }

            if (!id.startsWith("MV") && !id.startsWith("SH")) {
                id = splitId[splitId.length - 2];
            }

            if (id.startsWith("MV"))
                return `https://portal.${BUILD_ENVIRONMENT}.thefilter.com/images/${id}.jpg`;

            if (id.startsWith("SH"))
                return `https://portal.${BUILD_ENVIRONMENT}.thefilter.com/images/series/${id}.jpg`;
        }

    } catch (error) {
        if (result["id"] && (imageUrl !== undefined || imageDotUrl !== undefined)) {
            Logger.log(`Failed to load image for item ${result["id"]}: ${imageUrl} or ${imageDotUrl}`);
        }
    }

    return placeholder;

}

export function getErrorImage(event: SyntheticEvent<HTMLImageElement, Event>): void {
    event.currentTarget.src = placeholder;
}

export function getPlaceholderImage(event: SyntheticEvent<HTMLImageElement, Event>, item: Record<string, any>): void {
    let title = item["name"] || "";
    const genre = getGenres(item)[0] || "";
    const typeName = item["typeName"] || "";

    if (title.length > 30) {
        title = title.substring(0, 30) + "...";
    }
    title = title.replaceAll("&", "&amp;");

    switch (genre.toLowerCase()) {
        case "action":
        case "action and adventure":
        case "adventure":
            event.currentTarget.src = placeholders.getActionPlaceholder(title);
            break;
        case "comedy":
        case "sitcom":
            event.currentTarget.src = placeholders.getComedyPlaceholder(title);
            break;
        case "cooking":
        case "food":
            event.currentTarget.src = placeholders.getCookingPlaceholder(title);
            break;
        case "cycling":
            event.currentTarget.src = placeholders.getCyclingPlaceholder(title);
            break;
        case "documentary":
        case "documentaries":
        case "educational":
        case "history":
        case "culture":
        case "arts":
        case "bio":
            event.currentTarget.src = placeholders.getDocumentaryPlaceholder(title);
            break;
        case "drama":
        case "entertainment":
            event.currentTarget.src = placeholders.getDramaPlaceholder(title);
            break;
        case "fantasy":
        case "fantasy & adventure":
        case "fantasy and adventure":
            event.currentTarget.src = placeholders.getFantasyPlaceholder(title);
            break;
        case "gameshow":
        case "quiz":
        case "gaming":
            event.currentTarget.src = placeholders.getGameshowPlaceholder(title);
            break;
        case "horror":
            event.currentTarget.src = placeholders.getHorrorPlaceholder(title);
            break;
        case "kids":
        case "kids and family":
        case "7+":
        case "6 years +":
        case "2 - 5 years":
        case "animation":
        case "kids 2-5":
        case "kids teens":
            event.currentTarget.src = placeholders.getKidsPlaceholder(title);
            break;
        case "medical":
            event.currentTarget.src = placeholders.getMedicalPlaceholder(title);
            break;
        case "motorsports":
        case "motor":
            event.currentTarget.src = placeholders.getMotorsportsPlaceholder(title);
            break;
        case "music":
        case "classical music":
        case "music videos":
        case "jazz":
        case "opera":
            event.currentTarget.src = placeholders.getMusicPlaceholder(title);
            break;
        case "nature":
        case "gardening":
            event.currentTarget.src = placeholders.getNaturePlaceholder(title);
            break;
        case "news":
        case "reports":
        case "business":
            event.currentTarget.src = placeholders.getNewsPlaceholder(title);
            break;
        case "radio":
            event.currentTarget.src = placeholders.getRadioPlaceholder(title);
            break;
        case "romance":
            event.currentTarget.src = placeholders.getRomancePlaceholder(title);
            break;
        case "royalty":
            event.currentTarget.src = placeholders.getRoyaltyPlaceholder(title);
            break;
        case "science":
            event.currentTarget.src = placeholders.getSciencePlaceholder(title);
            break;
        case "sci-fi":
        case "science fiction and fantasy":
        case "sci-fi and fantasy":
            event.currentTarget.src = placeholders.getSciFiPlaceholder(title);
            break;
        case "shopping":
            event.currentTarget.src = placeholders.getShoppingPlaceholder(title);
            break;
        case "short film":
        case "cinema":
            event.currentTarget.src = placeholders.getMoviePlaceholder(title);
            break;
        case "soap":
        case "reality":
        case "lifestyle":
        case "celebrity":
            event.currentTarget.src = placeholders.getTVPlaceholder(title);
            break;
        case "sports":
        case "football":
        case "basketball":
        case "tennis":
        case "watersports":
        case "wintersports":
        case "athletics":
        case "fighting":
            event.currentTarget.src = placeholders.getSportsPlaceholder(title);
            break;
        case "stand-up":
        case "stand-up comedy":
        case "talkshow":
        case "interview":
            event.currentTarget.src = placeholders.getStandUpPlaceholder(title);
            break;
        case "thriller":
            event.currentTarget.src = placeholders.getThrillerPlaceholder(title);
            break;
        case "travel":
            event.currentTarget.src = placeholders.getTravelPlaceholder(title);
            break;
        case "vault":
            event.currentTarget.src = placeholders.getVaultPlaceholder(title);
            break;
        case "weather":
            event.currentTarget.src = placeholders.getWeatherPlaceholder(title);
            break;
        default:
            switch (typeName.toLowerCase()) {
                case "movie":
                    event.currentTarget.src = placeholders.getMoviePlaceholder(title);
                    break;
                case "musicrecording":
                    event.currentTarget.src = placeholders.getMusicPlaceholder(title);
                    break;
                case "compilation":
                    event.currentTarget.src = placeholders.getCompilationPlaceholder(title);
                    break;
                case "tvseries":
                case "creativework":
                case "creativeworkseason":
                    event.currentTarget.src = placeholders.getTVPlaceholder(title);
                    break;
                case "user":
                    event.currentTarget.src = placeholders.getUserPlaceholder(title);
                    break;
                case "cluster":
                    switch (title) {
                        case UserClusterTypes.movie:
                            event.currentTarget.src = placeholders.getClusterMoviePlaceholder(title);
                            break;
                        case UserClusterTypes.tvseries:
                        case UserClusterTypes.reality:
                            event.currentTarget.src = placeholders.getClusterTVSeriesPlaceholder(title);
                            break;
                        case UserClusterTypes.action:
                            event.currentTarget.src = placeholders.getClusterActionPlaceholder(title);
                            break;
                        case UserClusterTypes.documentary:
                            event.currentTarget.src = placeholders.getClusterDocumentaryPlaceholder(title);
                            break;
                        case UserClusterTypes.drama:
                            event.currentTarget.src = placeholders.getClusterDramaPlaceholder(title);
                            break;
                        case UserClusterTypes.family:
                            event.currentTarget.src = placeholders.getClusterFamilyPlaceholder(title);
                            break;
                        case UserClusterTypes.mixed:
                            event.currentTarget.src = placeholders.getClusterMixedPlaceholder(title);
                            break;
                    }
                    break;
                default:
                    event.currentTarget.src = placeholders.getDefaultPlaceholder(title);
                    break;
            }
            break;
    }
}

export function getErrorLogo(event: SyntheticEvent<HTMLImageElement, Event>): void {
    event.currentTarget.src = `https://portal.${BUILD_ENVIRONMENT}.thefilter.com/static/logos/24i_logo.png`;
}

export function getFormattedParameters(parameters: string): string {
    if (isEmptyOrSpaces(parameters)) {
        return "";
    }

    return parameters
        .split("\n")
        .filter((parameter: string) => {
            return !isEmptyOrSpaces(parameter);
        })
        .map((parameter: string) => {
            const keyAndValue = parameter.split(":");
            let result = `&${encodeURIComponent(keyAndValue[0].trim())}`;
            if (keyAndValue.length > 1) {
                result += `=${encodeURIComponent(keyAndValue[1].trim())}`;
            }
            return result;
        })
        .join("");
}

// modified from: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getParameterByKey(key: string, parameters: string): string {
    const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(parameters);
    if (!results) return "";
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getParametersAsJson(parameters: string, seedId: string | undefined, userId: string | undefined): string {
    const result: Record<string, any> = {};
    parameters.split("\n")
        .filter((parameter: string) => {
            return !isEmptyOrSpaces(parameter);
        })
        .forEach((parameter: string) => {
            const keyAndValue = parameter.split(":");
            const key = keyAndValue[0].trim();
            let value: string | string[] = keyAndValue[1].trim();
            if (value.indexOf(",") > 0) {
                value = value.split(",");
            }
            result[key] = value;
        });

    if (seedId) {
        if (result.hasOwnProperty("seedIds")) {
            result["seedIds"].push(seedId);
        } else {
            result["seedIds"] = [`${seedId}`];
        }
    }

    if (userId) {
        result["userId"] = `${userId}`;
    }

    return JSON.stringify(result);
}

export function diffAssociations(
    thing: Record<string, any> | null,
    orig: EntityAssociation[],
    updated: EntityAssociation[]
): Record<string, any> {

    let add: Record<string, any>[] = [];
    let remove: Record<string, any>[] = [];

    if (thing) {
        add = updated.filter(item => orig.indexOf(item) < 0).map((assoc) => {
            return {
                "entityId": assoc.entityId,
                "entityName": assoc.entityName,
                "entityType": assoc.entityType,
                "entityWeight": assoc.entityWeight,
                "thingId": thing.thingId,
                "thingName": thing.thingName,
                "thingType": thing.thingTypeName
            };
        });

        remove = orig.filter(item => updated.indexOf(item) < 0).map((assoc) => {
            return {
                "id": assoc.id,
                "entityId": assoc.entityId,
                "entityName": assoc.entityName,
                "entityType": assoc.entityType,
                "entityWeight": assoc.entityWeight,
                "thingId": thing.thingId,
                "thingName": thing.thingName,
                "thingType": thing.thingTypeName
            };
        });
    } else {
        add = updated.filter(item => orig.indexOf(item) < 0);
        remove = orig.filter(item => updated.indexOf(item) < 0);
    }

    return {
        add,
        remove
    };
}

export function addAssociations(
    thing: Record<string, any> | null,
    orig: EntityAssociation[],
    updated: EntityAssociation[]
): Record<string, any> {

    let add: Record<string, any>[] = [];

    if (thing) {
        add = updated.filter(item => orig.indexOf(item) < 0).map((assoc) => {
            return {
                "entityId": assoc.entityId,
                "entityName": assoc.entityName,
                "entityType": assoc.entityType,
                "entityWeight": assoc.entityWeight,
                "thingId": thing.thingId,
                "thingName": thing.thingName,
                "thingType": thing.thingTypeName
            };
        });
    } else {
        add = updated.filter(item => orig.indexOf(item) < 0);
    }

    return {
        add
    };
}

export function getThingFromMetadataIfSelected(metadataArray: Array<Record<string, any>> | undefined, customer: string): Array<Record<string, any>> {
    const things: Array<Record<string, any>> = [];

    if (metadataArray !== undefined) {
        if (metadataArray.length > 0) {
            metadataArray.forEach(metadata => {
                const thing = {
                    thingId: metadata[customer].id,
                    thingName: metadata[customer].name,
                    thingTypeName: metadata[customer].typeName
                };

                things.push(thing);
            });

            return things;
        }
    }

    return [{}];
}

export function getThingFromMetadata(metadata: Record<string, any>, customer: string): Record<string, any> {
    const thing = {
        thingId: metadata[customer].id,
        thingName: metadata[customer].name,
        thingTypeName: metadata[customer].typeName
    };

    return thing;
}

export function addPortalUserInfoToUrl(url: string, username: string, groups: string[]): string {
    let urlWithUserInfo = url;
    if (urlWithUserInfo.indexOf("?") > 0) {
        if (!urlWithUserInfo.endsWith("&")) urlWithUserInfo += "&";
    } else {
        urlWithUserInfo += "?";
    }

    urlWithUserInfo += "portalUser=" + username;

    if (groups.length > 0) {
        urlWithUserInfo += "&portalGroups=" + groups.join(",");
    }

    return urlWithUserInfo;
}

export function formatTypeAheadItems(items: string[]): Record<string, any>[] {
    return items.map((item, i) => {
        return {
            label: item,
            customOption: true,
            id: `new-id-${i}`
        };
    });
}

export function diffItemMetadata(
    thingId: string,
    orig: Record<string, any>,
    updated: MetadataOverride | null,
    username: string
): Record<string, any> {

    const timestamp = moment(new Date().toUTCString()).format(DATE_FORMAT);
    const add: Record<string, any>[] = [];
    const remove: Record<string, any>[] = [];

    if (updated) {
        for (const [key, value] of Object.entries(updated)) {
            add.push({
                thingId,
                createdBy: username,
                dateCreated: timestamp,
                dateUpdated: timestamp,
                lastUpdatedBy: username,
                field: key,
                overrides: value
            });
        }
    } else {
        return {};
    }

    return {
        add,
        remove
    };
}

export function arrayMove(arr: Array<any>, oldIndex: number, newIndex: number): Array<any> {
    if (newIndex < 0 || newIndex >= arr.length) {
        return arr;
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}

export function getSlotName(slotId: string, slots: Slot[]): string {
    if (slotId !== "all") {
        const matchingSlot = slots.filter((slot: Slot) => {
            return slot.slotId === slotId;
        })[0];

        if (matchingSlot) {
            return matchingSlot.slotName;
        } else {
            return slotId;
        }
    } else {
        return slotId;
    }
}

export function generateGuid(): string {
    return uuidv4();
}

const LiveItem = styled.div`
    background-color: greenyellow;
    box-shadow: 0 0 10px black;
    height: 10px;
    width: 10px;
    border-radius: 50%;
`;

const LiveRec = styled.div`
    color: aliceblue;
    background: ${Branding.twentyfouriRed};
    border-radius: 3px;
    width: 45px;
    text-align: center;
    box-shadow: 0 0 10px black;
`;

export function isLiveItem(item: Record<string, any>): ReactNode {
    if (item["isLive"]) {
        return <LiveRec>&#9679;LIVE</LiveRec>;
    }

    else if (item["isLiveBroadcast"]) {
        return <LiveItem />;
    }

}

export function isPromotedItem(
    item: Record<string, any>,
    slotId: string,
    promotions: Array<Record<string, any>>
): { metadataId: string, promotionId: string; endDate: string; promotionName: string } | null {

    for (const promotion of promotions) {
        if (promotion.slot === slotId || promotion.slot === "all") {
            for (const metadata of promotion.content.metadata) {
                if (metadata.id === item.id) {
                    return {
                        metadataId: metadata.id,
                        promotionId: promotion.promotionId,
                        endDate: promotion.endDate,
                        promotionName: promotion.promotionName
                    };
                }
            }
        }
    }

    return null;
}