import React, { ReactElement, ReactNode } from "react";
import {
    Button, Carousel, Col, Container, Form, InputGroup,
    OverlayTrigger, Row, Spinner, Tooltip
} from "react-bootstrap";
import styled from "styled-components";
import {
    FileEarmarkRichtextFill as PageIcon,
    Search as SearchIcon
} from "react-bootstrap-icons";

import MetadataCarousel from "../components/MetadataCarousel";
import SearchModal from "../components/SearchModal";
import * as requests from "../utils/Requests";
import * as utils from "../utils/Utils";
import * as constants from "../constants";
import { ComposerElementTypes } from "../enums";
import { BUILD_ENVIRONMENT } from "../constants";
import SeedAndUserHistoryPanel from "../components/SeedAndUserHistoryPanel";
import { getItemMetadata } from "../utils/Requests";
import Logger from "../utils/Logger";
import RandomField from "../components/fields/RandomField";
import { getAvailableCustomers } from "../config/Customers";
import Branding from "../config/Branding";

const SpinnerDiv = styled.div`
    position: absolute;
    top: 55%;
    right: 50%;
    z-index: 10;
`;

const CarouselRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
`;

const HeaderRow = styled(Row)`
    border: thin solid ${Branding.superDarkBackground};
    padding: 5px 0px;
    margin: 20px 0px;
    border-radius: 3px;
`;

const SpacerRow = styled(Row)`
    background-color: ${Branding.black};
    margin-top: 10px;
    margin-bottom: 10px;
    height: 30px;
`;

const FormEntry = styled.div`
    padding: 2pt;
    width: 100%;
    text-align: center;
`;

type ModelComparisonState = {
    showSearchModal: boolean,
    refresh: boolean,
    view: string,
    displayElementItems: Record<string, any>,
    displayElementTitles: Record<string, string>,
    availableCustomers: string[],
    customer: string,
    userId: string,
    seedId: string,
    availablePages: Record<string, any>[],
    pageId: string,
    pageName: string,
    elements: ComposerElement[],
    fppElements: ComposerElement[],
    dateCreated?: string,
    createdBy?: string,
    hasLoadedElements: boolean,
    seedItem?: Record<string, any>,
    showSeedAndHistoryPanel: boolean,
    showMessageForCustomer: boolean,
    initialLoad: boolean
}

type ModelComparisonProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

export default class ModelComparison extends React.Component<ModelComparisonProperties, ModelComparisonState> {
    private VIEW = "view";

    constructor(props: ModelComparisonProperties) {
        super(props);

        this.state = {
            showSearchModal: false,
            refresh: false,
            view: this.VIEW,
            displayElementItems: {},
            displayElementTitles: {},
            availableCustomers: [],
            customer: "",
            userId: "",
            seedId: "",
            availablePages: [],
            pageId: "",
            pageName: "",
            elements: [],
            fppElements: [],
            hasLoadedElements: false,
            showSeedAndHistoryPanel: false,
            showMessageForCustomer: false,
            initialLoad: true
        };
    }

    public componentDidMount(): void {
        const availableCustomers = getAvailableCustomers(this.props.groups).map((customer) => { return customer.name; });

        this.setState({ availableCustomers });
        this.getPages();
        this.update();
    }

    private update = (): void => {
        this.setState({
            seedItem: undefined
        });

        if (this.state.seedId) {
            this.getSeedInfo();
        }
    }

    private getSeedInfo = async (): Promise<void> => {
        if (this.props.customerConfig.name) {
            await getItemMetadata(this.props.customerConfig, this.state.seedId).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        this.setState({
                            seedItem: response.items[0]
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private toggleSeedAndHistoryPanel = (): void => {
        this.setState({
            showSeedAndHistoryPanel: !this.state.showSeedAndHistoryPanel
        });
    }

    private async getPages(): Promise<void> {
        await requests.getComposerPagesByCustomer(this.props.customerConfig.name).then((pages: Record<string, any>[]) => {
            const filteredPages = pages.filter((page) => {
                return page["pageName"].toLowerCase().includes("comparison") || page["pageName"].toLowerCase().includes("vs");
            });

            if ( filteredPages.length > 0) {
                this.setState({
                    availablePages: filteredPages,
                    pageId: filteredPages[0]["pageId"],
                    refresh: this.state.refresh,
                    showMessageForCustomer: false,
                    initialLoad: false
                }, () => {
                    if (this.state.view === this.VIEW) {
                        this.setPage(filteredPages[0]["pageId"]);
                    }
                });
            } else {
                this.setState({
                    showMessageForCustomer: true,
                    initialLoad: false
                });
            }
        });
    }

    private getTitle(elementTitle: string, responseJson: Record<string, any>): string {
        let formattedElementTitle = elementTitle;

        if (formattedElementTitle.indexOf("[SEED_TITLE]") > 0 && responseJson["modelInfo"] != null) {
            const seedInfo = responseJson.modelInfo.seed;
            formattedElementTitle = formattedElementTitle.replace("[SEED_TITLE]", seedInfo.name);
        }

        return formattedElementTitle;
    }

    private requestElementData(): void {
        const allComposerElements = this.state.elements.concat(this.state.fppElements);
        allComposerElements.forEach(async (element: ComposerElement) => {

            const customerConfig = this.props.customerConfig;
            let itemsRequest = null;
            let recommendationUrl = null;
            switch (element.type) {
                case ComposerElementTypes.FixedCarousel:
                case ComposerElementTypes.FixedLargeCarousel:
                case ComposerElementTypes.FixedHero:
                case ComposerElementTypes.FPPCarousel:
                    itemsRequest = await requests.lookupItemsList(
                        customerConfig,
                        element.seedIds.replace("[SEED_ID]", this.state.seedId)
                    );

                    itemsRequest.json().then(async (responseJson) => {
                        const displayElementItems = this.state.displayElementItems;
                        displayElementItems[element.key] = responseJson.items;
                        const displayElementTitles = this.state.displayElementTitles;
                        displayElementTitles[element.key] = this.getTitle(element.title, responseJson);
                        this.setState({
                            displayElementItems,
                            displayElementTitles
                        });
                    });
                    break;
                case ComposerElementTypes.FixedUserCarousel:
                    itemsRequest = await requests.lookupItemsList(customerConfig, element.seedIds);

                    itemsRequest.json().then(async (responseJson) => {
                        const displayElementItems = this.state.displayElementItems;
                        const displayElementTitles = this.state.displayElementTitles;
                        const historyItems = responseJson.items || [];
                        historyItems.unshift({
                            name: element.username,
                            typeName: "user"
                        },
                            {
                                name: element.cluster,
                                typeName: "cluster"
                            });
                        displayElementItems[element.key] = historyItems;
                        displayElementTitles[element.key] = this.getTitle(element.title, responseJson);
                        this.setState({
                            displayElementItems,
                            displayElementTitles
                        });
                    });
                    break;
                case ComposerElementTypes.Spacer:
                    break;
                case ComposerElementTypes.FullPagePersonalisation:
                    if (!this.state.hasLoadedElements) {
                        recommendationUrl = element.slotUrl.replace("[ENVIRONMENT]", BUILD_ENVIRONMENT)
                            .replace("[SEED_ID]", this.state.seedId);

                        if (recommendationUrl.indexOf("?") > 0) {
                            if (!recommendationUrl.endsWith("&")) recommendationUrl += "&";
                        } else {
                            recommendationUrl += "?";
                        }

                        recommendationUrl += `userId=${encodeURIComponent(this.state.userId.trim())}`;

                        recommendationUrl = utils.addPortalUserInfoToUrl(recommendationUrl, this.props.username, this.props.groups);

                        itemsRequest = await requests.makeSlotRequest(recommendationUrl);

                        itemsRequest.json().then(responseJson => {
                            const fppElements: Array<ComposerElement> = [];
                            responseJson.results.forEach((result: Record<string, any>) => {
                                fppElements.push({
                                    key: utils.generateGuid(),
                                    title: result["title"],
                                    type: ComposerElementTypes.FPPCarousel,
                                    customer: this.props.customerConfig.name,
                                    seedIds: result["items"].map((item: Record<string, any>) => {
                                        return item["id"];
                                    }).join(",")
                                });
                            });

                            this.setState({
                                fppElements,
                                hasLoadedElements: true
                            }, this.refresh);
                        });
                    }
                    break;
                default:
                    recommendationUrl = element.slotUrl.replace("[ENVIRONMENT]", BUILD_ENVIRONMENT)
                        .replace("[SEED_ID]", this.state.seedId) + `&userId=${encodeURIComponent(this.state.userId.trim())}`;

                    recommendationUrl = utils.addPortalUserInfoToUrl(recommendationUrl, this.props.username, this.props.groups);

                    try {
                        itemsRequest = await requests.makeSlotRequest(recommendationUrl);

                        const responseJson = await itemsRequest.json();
                        const displayElementItems = this.state.displayElementItems;
                        displayElementItems[element.key] = responseJson.items;

                        const displayElementTitles = this.state.displayElementTitles;
                        displayElementTitles[element.key] = this.getTitle(element.title, responseJson);
                        this.setState({
                            displayElementItems,
                            displayElementTitles
                        });
                    } catch (error) {
                        Logger.log("Error fetching data:", error);
                        // What do we want here when no items are found
                    }
                    break;
            }
        });

        this.setState({
            refresh: false
        }, this.update);
    }

    private viewElements(): ReactNode {
        const elements: ReactElement[] = [];
        const allComposerElements = this.state.elements.concat(this.state.fppElements);

        allComposerElements.forEach((element: ComposerElement) => {
            switch (element.type) {
                case ComposerElementTypes.Carousel:
                case ComposerElementTypes.FixedCarousel:
                case ComposerElementTypes.FPPCarousel:
                case ComposerElementTypes.SlotCarousel:
                    elements.push(this.createCarouselRow(element, false));
                    break;
                case ComposerElementTypes.LargeCarousel:
                case ComposerElementTypes.FixedLargeCarousel:
                case ComposerElementTypes.SlotLargeCarousel:
                    elements.push(this.createCarouselRow(element, true));
                    break;
                case ComposerElementTypes.Hero:
                case ComposerElementTypes.FixedHero:
                case ComposerElementTypes.SlotHero:
                    elements.push(this.createHeroRow(element));
                    break;
                case ComposerElementTypes.FixedUserCarousel:
                    elements.push(this.createUserCarouselRow(element));
                    break;
                case ComposerElementTypes.Spacer:
                    elements.push(this.createSpacerRow());
                    break;
                default:
                    break;
            }
        });

        return elements;
    }

    private createCarouselRow(element: ComposerElement, largeCarousel: boolean): ReactElement {
        const title = this.state.displayElementTitles[element.key];
        const items = this.state.displayElementItems[element.key];

        return (
            <CarouselRow key={element.key}>
                <Col md style={{ textAlign: "left" }}>
                    <h5 style={{ paddingLeft: "40px" }} className="mx-auto">{title}</h5>
                    {this.state.displayElementItems[element.key] &&
                        <MetadataCarousel
                            items={items}
                            customer={this.props.customerConfig}
                            hideInfoIcon
                            modal
                            large={largeCarousel}
                            username={this.props.username}
                            groups={this.props.groups}
                        />
                    }
                </Col>
            </CarouselRow>
        );
    }

    private createUserCarouselRow(element: ComposerElement): ReactElement {
        const title = this.state.displayElementTitles[element.key];
        const items = this.state.displayElementItems[element.key];

        return (
            <CarouselRow key={element.key}>
                <Col md style={{ textAlign: "left" }}>
                    <h5 style={{ paddingLeft: "40px" }} className="mx-auto">{title}</h5>
                    {this.state.displayElementItems[element.key] &&
                        <MetadataCarousel items={items}
                            customer={this.props.customerConfig}
                            hideInfoIcon
                            modal
                            username={this.props.username}
                            groups={this.props.groups}
                        />
                    }
                </Col>
            </CarouselRow>
        );
    }

    private createHeroRow(element: ComposerElement): ReactElement {
        const heroItems = this.state.displayElementItems[element.key];
        const customerConfig = this.props.customerConfig;

        let imageClassName = "d-block w-100";
        if (customerConfig.portraitImages) {
            imageClassName = "d-block w-80 mx-auto";
        }

        if (heroItems) {
            return (
                <Row key={utils.generateGuid()}>
                    <Col md={{ "span": 4, "offset": 4 }}>
                        <Carousel key={element.key} pause={false}>
                            {heroItems.map((hero: Record<string, any>, i: number) => {
                                return <Carousel.Item key={`${element.key}-${i}`}>
                                    <img
                                        className={imageClassName}
                                        src={utils.getImageUrl(hero)}
                                        alt={hero["name"]}
                                    />
                                </Carousel.Item>;
                            })}
                        </Carousel>
                    </Col>
                </Row>
            );
        } else {
            return <></>;
        }
    }

    private createSpacerRow(): ReactElement {
        return (
            <SpacerRow key={utils.generateGuid()}>
                <hr style={{ border: "solid thin white", width: "80%" }} />
            </SpacerRow>
        );
    }

    private displaySearchModal(show: boolean): void {
        this.setState({
            showSearchModal: show
        });
    }

    private setUserId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            userId: callbackValue,
            hasLoadedElements: false
        }, () => {
            this.update();
            this.refresh();
        });
    }

    private setSeedId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            seedId: callbackValue,
            hasLoadedElements: false
        }, () => {
            this.update();
            this.refresh();
        });
    }

    private setElements = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const selectedValue = event.currentTarget.value;
        this.setState({
            refresh: true
        }, () => {
            this.setPage(selectedValue);
        });
    }

    private async setPage(pageId: string): Promise<void> {
        if (this.state.view === this.VIEW) {
            await requests.getComposerPageById(pageId).then((page) => {
                const pageDefinition = JSON.parse(page.pageDefinition);
                const pageName = page.pageName;
                const customer = page.customer;
                const elements = pageDefinition;
                const dateCreated = page.dateCreated;
                const createdBy = page.createdBy;
                const userId = page.pageUserId || "b64451b63d079bb41152678d393279e54348594d24e67d15279200c19dc2a8bf";
                const seedId = page.pageSeedId || "";

                this.setState({
                    elements,
                    customer,
                    userId,
                    seedId,
                    pageName,
                    pageId,
                    dateCreated,
                    createdBy,
                    displayElementTitles: {},
                    displayElementItems: {}
                }, this.requestElementData);
            });
        }
    }

    private refresh(): void {
        this.setState({
            displayElementItems: {}
        }, this.requestElementData);
    }

    public render(): ReactNode {
        const pageOptions = this.state.availablePages.map((page: Record<string, any>, i: number) => {
            const pageName = page["pageName"];
            const pageId = page["pageId"];
            return <option value={pageId} key={pageId}>
                {pageName}
            </option>;
        });

        return (
            <div style={{ padding: "0px 15px" }}>
                <SearchModal
                    customer={this.props.customerConfig}
                    showModal={this.state.showSearchModal}
                    userId={this.state.userId}
                    closeCallback={() => { this.displaySearchModal(false); }}
                    username={this.props.username}
                    groups={this.props.groups}
                />
                {this.state.refresh && <SpinnerDiv>
                    <Spinner animation="border" style={{ color: "white" }} />
                </SpinnerDiv>}
                <Container className="mw-100" key="composable-container" style={{ minHeight: "86vh" }}>
                    {this.state.initialLoad && (
                        <div className="text-center my-5">Loading...</div>
                    )}
                    {this.state.showMessageForCustomer && !this.state.initialLoad && (
                        <div className="text-center my-5">No models to compare for this customer</div>
                    )}
                    {!this.state.showMessageForCustomer && !this.state.initialLoad &&
                        <>
                            <HeaderRow>
                                <Col md={11}>
                                    <Form inline onSubmit={(event) => { event.preventDefault(); }}>
                                        {this.state.view === this.VIEW &&
                                            <>
                                                <Form.Group style={{ marginRight: "10px" }}>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="page-tooltip">Page</Tooltip>}
                                                            >
                                                                <InputGroup.Text><PageIcon size="22" /></InputGroup.Text>
                                                            </OverlayTrigger>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            as="select"
                                                            id="dropdown-page"
                                                            title="Page"
                                                            onChange={this.setElements}
                                                            value={this.state.pageId}
                                                            style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem", "height": "37px" }}>
                                                            {pageOptions}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group>
                                                    <FormEntry>
                                                        <RandomField
                                                            customer={this.props.customerConfig}
                                                            type={constants.USER}
                                                            includeIcon
                                                            initialValue={this.state.userId}
                                                            onChangeCallback={this.setUserId}
                                                            placement="top"
                                                        />
                                                    </FormEntry>
                                                </Form.Group>

                                                <Form.Group>
                                                    <FormEntry>
                                                        <RandomField
                                                            customer={this.props.customerConfig}
                                                            type={constants.SEED}
                                                            includeIcon
                                                            initialValue={this.state.seedId}
                                                            onChangeCallback={this.setSeedId}
                                                            placement="top"
                                                        />
                                                    </FormEntry>
                                                </Form.Group>
                                                {this.state.seedItem && <><b style={{ marginLeft: "5px" }}>Name:&nbsp;</b>{this.state.seedItem.name}</>}
                                            </>
                                        }
                                    </Form>
                                </Col>
                                <Col md={{ span: 1 }} className="d-flex justify-content-end">
                                    {this.state.view === this.VIEW && this.props.customerConfig.name &&
                                        <Button variant="primary" type="button" onClick={() => { this.displaySearchModal(true); }}>
                                            <SearchIcon />
                                        </Button>
                                    }
                                </Col>
                            </HeaderRow>
                            {this.state.view === this.VIEW &&
                                <div style={{ opacity: this.state.refresh ? 0.5 : 1 }}>
                                    {this.viewElements()}
                                </div>
                            }
                        </>
                    }
                </Container>
                <SeedAndUserHistoryPanel
                    customerConfig={this.props.customerConfig}
                    userId={this.state.userId}
                    seedItem={this.state.seedItem}
                    showPanel={this.state.showSeedAndHistoryPanel}
                    showHidePanelCallback={this.toggleSeedAndHistoryPanel}
                />
            </div>
        );
    }
}
