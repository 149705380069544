import moment from "moment";
import React, { ReactNode } from "react";
import {Alert, Row, Col, Button, Form, Image, InputGroup, Modal, Spinner } from "react-bootstrap";
import styled from "styled-components";
import Logger from "../../utils/Logger";

import { getMultiItemMetadata, deletePromotion } from "../../utils/Requests";
import { getImageUrl } from "../../utils/Utils";

import {DATE_FORMAT} from "../../constants";
import Branding from "../../config/Branding";

const FieldName = styled(InputGroup.Text)`
    background: black;
    min-width: 70px;
`;

const FormRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const RemainingItems = styled.div`
    display: flex;
    color: ${Branding.white};
    background: ${Branding.primary};
    border-radius: 40px;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    user-select: none;
    cursor: pointer;
`;

const AssocAlert = styled(Alert)`
    position: absolute;
    top: 100px;
    right: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

type PromotionCardState = {
    promotionIds: string[],
    items: Record<string,any>[],
    loading: boolean,
    slots: Slot[]
    showDeleteModal: boolean,
    showDeleteError: boolean
}

type PromotionCardProperties = {
    promotion: Record<string, any>,
    readOnly?: boolean,
    customerConfig: CustomerConfig,
    slots: Slot[],
    username: string,
    groups: string[],
    editCallback: (promotion: Record<string,any>, items: Record<string,any>[]) => void,
    duplicateCallback: (promotion: Record<string,any>, items: Record<string,any>[]) => void,
    deleteCallback: () => void
}

export default class PromotionCard extends React.Component<PromotionCardProperties, PromotionCardState> {

    constructor(props: PromotionCardProperties) {
        super(props);

        this.state = {
            promotionIds: props.promotion.content.metadata.map((item: Record<string,any>) => { return item.id; }),
            items: [],
            loading: true,
            slots: [],
            showDeleteModal: false,
            showDeleteError: false
        };
    }

    public componentDidMount(): void {
        this.getItemMetadata();
    }

    private async getItemMetadata(): Promise<void> {
        this.setState({
            loading: true
        });

        await getMultiItemMetadata(this.props.customerConfig, this.state.promotionIds).then((response)=> {
            return response.json();
        }).then(jsonOutput => {
            this.setState({
                items: jsonOutput.items
            });
        }).catch(error => {
            Logger.error("Error getting item metadata: ", error);
        });

        this.setState({
            loading: false
        });
    }

    private renderPromotedItems(): ReactNode {
        const { items, loading } = this.state;
        const { promotion } = this.props;

        let renderItems: Record<string,any>[] = [];
        const remainingItemCount = items.length - 3;

        if (loading) {
            return (
                <Col style={{textAlign: "center"}}>
                    <Spinner animation={"border"} variant={"primary"} />
                </Col>
            );
        }

        if (items.length <= 4) {
            renderItems = items;
        } else {
            renderItems = items.slice(0,3);
        }

        const promotedItemsCarousel = renderItems.map((item: Record<string,any>) => {
            return (
                <Col key={`${promotion.promotionId}:${item.id}`} md={3} style={{textAlign: "center"}}>
                    <Row style={{height: "94%"}}>
                        <Col>
                            <Image src={getImageUrl(item)} rounded fluid/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {item.name}
                        </Col>
                    </Row>
                </Col>
            );
        });

        if (items.length > 4) {
            promotedItemsCarousel.push(
                <Col key={`${promotion.promotionId}:remaining`} md={3} style={{textAlign: "center"}}>
                    <Row style={{height: "94%"}}>
                        <Col className="d-flex justify-content-center align-items-center">
                            <RemainingItems>
                                +{remainingItemCount}
                            </RemainingItems>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            let spacerCount = 1;
            while (promotedItemsCarousel.length < 4) {
                promotedItemsCarousel.push(<Col key={`${promotion.promotionId}:spacer${spacerCount}`}  />);
                spacerCount++;
            }
        }

        return promotedItemsCarousel;
    }

    private editCallback = () => {
        if (this.props.editCallback) {
            this.props.editCallback(this.props.promotion, this.state.items);
        }
    }

    private duplicateCallback = () => {
        if (this.props.duplicateCallback) {
            this.props.duplicateCallback(this.props.promotion, this.state.items);
        }
    }

    private handleDeleteModalShow() {
        this.setState({
            showDeleteModal: true
        });
    }

    private handleDeleteModalClose() {
        this.setState({
            showDeleteModal: false
        });
    }

    private resetAndClosePromoCard(){
        if (this.props.deleteCallback) {
            this.props.deleteCallback();
        }
    }

    private handleDeleteRequest = async () => {
        await deletePromotion(this.props.customerConfig, this.props.promotion.promotionId).then((response) => {
            if (response.ok) {

                if (response.status === 200) {
                    this.resetAndClosePromoCard();
                } else {
                    this.handleDeleteError(Error(`Error during deletion, API response: ${response.status} - ${response.statusText}`));
                }
            }
        }).catch((error: Error) => {
            this.handleDeleteError(error);
        });
    }

    private handleDeletePromotion() {
        try {
            this.handleDeleteRequest();
        } catch {
            setTimeout(() => {
                this.setState({
                    showDeleteError: true
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showDeleteError: false
                });
            }, 4000);
        }
        this.handleDeleteModalClose();
    }

    private handleDeleteError(error: Error) {
        Logger.error(error);
        this.setState({
            showDeleteError: true
        });
    }

    private closeDeleteError = (): void => {
        this.setState({
            showDeleteError: false
        });
    }

    public render(): ReactNode {
        const promotion = this.props.promotion;

        const created = moment.duration(moment(promotion.dateCreated).diff(moment.utc())).humanize(true);
        const updated = moment.duration(moment(promotion.dateUpdated).diff(moment.utc())).humanize(true);

        const availableSlots = this.props.slots.sort((a,b) => {
            return a.slotName.localeCompare(b.slotName);
        }).map((slot) => {
            return (
                <option value={slot.slotId} key={slot.slotId}>
                    {slot.slotName} ({slot.slotId})
                </option>
            );
        });

        availableSlots.push(
            <option value={"all"} key={"all"}>
                All
            </option>
        );

        return (
            <>
                <Modal
                    show={this.state.showDeleteModal}
                    onHide={()=> { this.handleDeleteModalClose(); }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Promotion?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Do you want to delete the promotion:
                            <br/>
                            <b>{this.props.promotion.promotionName}</b>
                            <br/>
                            <b>This cannot be undone</b>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.handleDeleteModalClose();}}>Cancel</Button>
                        <Button variant="danger" onClick={() => {this.handleDeletePromotion();}}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                <AssocAlert
                    variant='danger'
                    show={this.state.showDeleteError}
                    onClose={this.closeDeleteError}
                    dismissible
                >
                    Error deleting page
                </AssocAlert>

                <Row>
                    <Col md={9}>
                        <Row>
                            <Col>
                                <h5>{promotion.promotionName}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    {this.renderPromotedItems()}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col>
                                <FormRow>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName>From</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                 type="date"
                                                 id={`${promotion.promotionId}-from`}
                                                 style={{"padding": "0.375rem"}}
                                                 value={moment(promotion.startDate).utc().format(DATE_FORMAT)}
                                                 disabled={this.props.readOnly}
                                            />
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName>To</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="date"
                                                id={`${promotion.promotionId}-to`}
                                                style={{"padding": "0.375rem"}}
                                                value={moment(promotion.endDate).utc().format(DATE_FORMAT)}
                                                disabled={this.props.readOnly}
                                            />
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName>Slot</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                id="dropdown-slot"
                                                title="Slot"
                                                style={{"padding": "0.375rem"}}
                                                value={promotion.slot}
                                                disabled={this.props.readOnly}
                                            >
                                                {availableSlots}
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col md="4" className="d-flex justify-content-center">
                                        <Button onClick={this.editCallback}>Edit</Button>
                                    </Col>
                                    <Col md="4" className="d-flex justify-content-center">
                                        <Button onClick={this.duplicateCallback}>Duplicate</Button>
                                    </Col>
                                    <Col md="4" className="d-flex justify-content-center">
                                        <Button onClick={() => {this.handleDeleteModalShow(); }} variant="danger">Delete</Button>
                                    </Col>
                                </FormRow>
                                <FormRow style={{paddingTop: "20px"}}>
                                    <Col>
                                        Created by {promotion.createdBy} {created}
                                        <br/>
                                        {promotion.updatedBy && <>
                                            Updated by {promotion.updatedBy} {updated}
                                        </>}
                                    </Col>
                                </FormRow>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}