import React, { ReactNode, RefObject } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import AssociationGrid from "../components/lanebuilder/AssociationGrid";
import SearchForm from "../components/lanebuilder/SearchForm";
import SearchTable from "../components/lanebuilder/SearchTable";
import MultiTaggingView from "../components/entity/MultiTaggingView";
import { getEntityAssociations, getHeroItemMetadata, getItemMetadata, itemSearch } from "../utils/Requests";
import { getThingFromMetadataIfSelected, seedIdCheck } from "../utils/Utils";
import Logger from "../utils/Logger";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Branding from "../config/Branding";

const EditorialRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin: 10px 0;
    padding: 5px 5px;
    background: ${Branding.nearBlack};
    margin-top: 38px;
`;

const SearchDiv = styled.div`
    overflow: auto;
    height: calc(100vh - 268px);
    width: 100%;
    background: ${Branding.nearBlack};
`;

const SearchCountDiv = styled.div`
    text-align: center;
    width: 100%;
    margin: 5px;
    height: 20px;
`;

const Associations = styled.div`
    overflow: auto;
    height: calc(100vh - 285px);
    width: 100%;
    border-radius: 3px;
    background: ${Branding.nearBlack};
    margin-bottom: 5px;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

const BlockingDiv = styled.div<BlockingDivProps>`
    pointer-events: ${props => props.blocking ? "none" : "all"};
    opacity: ${props => props.blocking ? 0.5 : 1};
    width: 100%;
`;

type MultiTaggingState = {
    alertMsg: string,
    alertType: "success" | "warning",
    selectedEditorialID: string,
    selectedEditorialName: string,
    selectedItems: Array<SearchItem>,
    selectedItemsMetaData?: Array<Record<string, any>>,
    originalItems: Array<EntityAssociation>,
    removedItems: Array<SearchItem>,
    searchResults: Array<SearchItem>,
    noResults: boolean,
    loading: boolean,
    showModal: boolean,
    unsaved: boolean,
}

type MultiTaggingProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

interface MultiTaggingProperties extends MultiTaggingProps, RouteComponentProps { }

class MultiTagging extends React.Component<MultiTaggingProperties, MultiTaggingState> {

    private searchFormElement: RefObject<SearchForm>;

    constructor(props: MultiTaggingProperties) {
        super(props);

        this.searchFormElement = React.createRef<SearchForm>();

        this.state = {
            alertMsg: "",
            alertType: "success",
            selectedEditorialID: "",
            selectedEditorialName: "",
            selectedItems: [],
            originalItems: [],
            searchResults: [],
            removedItems: [],
            noResults: false,
            loading: true,
            showModal: false,
            unsaved: false
        };
    }

    public componentDidMount(): void {
        setTimeout(() => { this.populateItems(); }, 500);
    }

    private async populateItems() {
        const { customerConfig } = this.props;

        try {
            const response = await getEntityAssociations(customerConfig, "");

            if (response.ok) {
                const responseJSON = await response.json();
                const originalAssociations = responseJSON;

                const items = responseJSON.map((item: EntityAssociation) => {
                    return {
                        "id": seedIdCheck(customerConfig.name, item.thingId)
                    };
                });

                const enrichedItems = await getHeroItemMetadata(customerConfig, items);

                this.setState({
                    originalItems: originalAssociations,
                    selectedItems: enrichedItems,
                    loading: false,
                    unsaved: false
                });
            } else {
                throw new Error("Error fetching entity associations");
            }
        } catch (error) {
            Logger.log("Error in populateItems:", error);
        }
    }


    public reset = (): void => {
        this.setState({
            originalItems: [],
            searchResults: [],
            selectedItems: [],
            selectedItemsMetaData: [],
            noResults: false,
            loading: true,
            showModal: false,
            unsaved: false
        }, () => {
            if (this.searchFormElement.current) {
                this.searchFormElement.current.reset();
            }

            this.populateItems();
        });
    }

    private resetSearch = (): void => {
        this.setState({
            searchResults: []
        });
    }

    private search = async (searchTerm: string): Promise<void> => {
        const { customerConfig } = this.props;

        const searchResults = await itemSearch(customerConfig, searchTerm);

        this.setState({
            searchResults,
            noResults: searchResults.length === 0
        });
    }

    private addToGroup = (item: SearchItem): void => {
        const { customerConfig } = this.props;
        const { selectedItems } = this.state;

        item["id"] = seedIdCheck(customerConfig.name, item.id);

        const itemIndex = selectedItems.findIndex(selectedItem => selectedItem === item);

        if (itemIndex < 0) {
            const newSelection = [...selectedItems, item];

            this.setState({
                selectedItems: newSelection,
                unsaved: true
            });
        }

        this.getItemMetadata();
    }

    private removeFromGroup = (itemToRemove: SearchItem): void => {
        const { selectedItems, removedItems } = this.state;

        const newSelection = selectedItems.filter((item) => {
            return item !== itemToRemove;
        });

        removedItems.push(itemToRemove);

        this.setState({
            selectedItems: newSelection,
            removedItems,
            unsaved: true
        }, () => {
            this.getItemMetadata();
        });
    }

    private async getItemMetadata(): Promise<void> {
        const { customerConfig } = this.props;
        const { selectedItems } = this.state;
        const ItemsMetadata: Array<Record<string, any>> = [];

        for (let i = 0; i < selectedItems.length; i++) {
            const selectedItem = selectedItems[i];

            if (selectedItem.id) {
                const newMetadata = await getItemMetadata(customerConfig, selectedItem.id).then(async (response) => {
                    const responseJSON = await response.json();
                    return responseJSON.items[0];
                });

                ItemsMetadata.push({
                    [customerConfig.name]: newMetadata
                });
            }
        }

        this.setState({
            selectedItemsMetaData: ItemsMetadata
        });
    }

    // Do we want to create a modal and option to delete all from group

    // private closeAlert = () => {
    //     this.setState({
    //         alertMsg: ""
    //     });
    // }

    // private displayModal = (show: boolean) => {
    //     this.setState({
    //         showModal: show
    //     });
    // }

    public render(): ReactNode {
        const { customerConfig, location } = this.props;
        const { loading, searchResults, noResults, selectedItems, selectedItemsMetaData } = this.state;

        return (
            <div>
                <Container className="mw-100" style={{ marginTop: "10px" }}>
                    <Row>
                        <Col md={4}>
                            <EditorialRow>
                                {loading && <SpinnerDiv>
                                    <Spinner animation="border" style={{ color: "white" }} />
                                </SpinnerDiv>}
                                <BlockingDiv blocking={loading}>
                                    <SearchForm
                                        ref={this.searchFormElement}
                                        resetCallback={this.resetSearch}
                                        searchCallback={this.search} />
                                    <SearchDiv>
                                        <SearchTable
                                            items={searchResults}
                                            noResults={noResults}
                                            callback={this.addToGroup}
                                        />
                                    </SearchDiv>
                                    <SearchCountDiv>
                                        {searchResults.length > 0 && <>Showing {searchResults.length} results</>}
                                    </SearchCountDiv>
                                </BlockingDiv>
                            </EditorialRow>
                        </Col>
                        <Col md={8} className="mx-auto">
                            <Row>
                                <Col md={12} >
                                    <MultiTaggingView
                                        key={JSON.stringify(selectedItems)}
                                        seedIds={selectedItems.map((item) => item.id)}
                                        customer={customerConfig.name}
                                        things={getThingFromMetadataIfSelected(selectedItemsMetaData, customerConfig.name)}
                                        origin={location.pathname.replace("/", "")}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col>
                                    <Associations>
                                        <AssociationGrid items={selectedItems} removeCallback={this.removeFromGroup} />
                                    </Associations>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default withRouter(MultiTagging);
