import React, { ReactNode } from "react";
import { Alert, Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import styled from "styled-components";

import MetadataCarousel from "../components/MetadataCarousel";
import * as customers from "../config/Customers";
import * as utils from "../utils/Utils";
import * as requests from "../utils/Requests";
import MetadataListGroup from "../components/MetadataListGroup";
import ItemEntityView from "../components/entity/ItemEntityView";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Logger from "../utils/Logger";
import Branding from "../config/Branding";

const MetadataCol = styled(Col)`
    border-radius: 3px;
    margin: 10px 0;
`;

const MLTRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
`;

const MLTCol = styled(Col)`
    text-align: center;
    padding 0;
`;

const SelectedEntityCol = styled(Col)`
    text-align: center;
    padding: 0;
`;

const MetadataCard = styled(Card)`
    padding-top: 10px;
`;

const MetadataImage = styled(Card.Img)`
    max-height: 250px;
    max-width: 94%;
    width: unset;

`;

const MetadataTabs = styled(Tabs)`
    margin: 0;
`;

const CentralCol = styled(Col)`
    text-align: center;
`;

const EntitiesCard = styled(Card)`
    padding: 10px;
    width: 100%;
`;

const DropdownContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
`;

const Dropdown = styled(Form.Control)`
    color: white;
    background-color: ${Branding.darkTransparentBackground};
    border-color: ${Branding.darkTransparentBackground};
`;

const LoadAlert = styled(Alert)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    width: 500px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type MetadataState = {
    itemMetadata?: Record<string, any>,
    seedId: string,
    mltItems: Record<string, any>[],
    customerConfig: CustomerConfig,
    allAssociations: EntityAssociation[],
    selectedTab: string,
    selectedTagItems: Record<string, any>[],
    dropDownValues: Record<string, any>[],
    selectedDropdownValue: string,
    showError: boolean,
    error: string
}

type MetadataProps = {
    standalone?: boolean,
    username: string,
    groups: string[],
    setCustomerCallback: (customerName: string) => void
}

interface MetadataProperties extends MetadataProps, RouteComponentProps { }

class Metadata extends React.Component<MetadataProperties, MetadataState> {
    // tabMap used to transform values given by the ItemEntityView to singular form
    static tabMap: { [key in "franchises" | "genres" | "people" | "tags" | "moods"]: string } = {
        franchises: "franchise",
        genres: "genre",
        people: "people",
        tags: "tag",
        moods: "mood"
    };

    constructor(props: MetadataProperties) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);

        const seedId = urlParams.get("seedId") || "";
        const customerConfig = customers.getCustomerConfig(urlParams.get("customer"));

        this.state = {
            seedId: seedId,
            mltItems: [],
            customerConfig: customerConfig,
            allAssociations: new Array<EntityAssociation>(),
            selectedTab: "",
            selectedTagItems: [],
            dropDownValues: [],
            selectedDropdownValue: "",
            showError: false,
            error: ""
        };

        // Should load before component mounts as we need a default value for the selection
        if (customerConfig.entitySlot !== "") {
            this.getAllAssociations();
        }

    }

    public componentDidMount(): void {
        if (this.state.seedId) {
            this.getItemMetadata();
        }

        this.props.setCustomerCallback(this.state.customerConfig.name);
    }

    public componentDidUpdate(_: any, prevState: MetadataState) {
        // Check if the selectedTab value has changed
        if (prevState.selectedTab !== this.state.selectedTab) {
            // Call this.renderSelectedTagItems() when the selectedTab value changes
            this.renderSelectedTagItems();
            this.initializeSelectedDropdownValue();
        }
    }

    private async getItemMetadata(): Promise<void> {
        const { seedId, customerConfig } = this.state;
        if (seedId) {
            const newMetadata = await requests.getItemMetadata(customerConfig, seedId).then(async (response) => {
                const responseJSON = await response.json();
                return responseJSON.items[0];
            });

            const itemMetadata = this.state.itemMetadata || {};
            itemMetadata[customerConfig.name] = newMetadata;

            this.setState({
                itemMetadata
            }, this.getItemMLT);
        }
    }

    private async getItemMLT(): Promise<void> {
        if (this.state.seedId) {
            const mltItems = await requests.getMLT(
                this.state.customerConfig,
                this.state.itemMetadata![this.state.customerConfig.name],
                this.props.username,
                this.props.groups
            );

            this.setState({
                mltItems
            });
        }
    }

    private async getAllAssociations(): Promise<void> {
        requests.getAssociations(this.state.customerConfig, this.state.seedId).then(response => {
            return response.json();
        }).then(jsonOutput => {
            if (typeof jsonOutput === "object" && jsonOutput !== null) {
                const entityTypeCounts: { [key: string]: number } = {};
                Object.keys(jsonOutput).forEach(key => {
                    const entityType = jsonOutput[key].entityType;
                    if (entityTypeCounts[entityType]) {
                        entityTypeCounts[entityType]++;
                    } else {
                        entityTypeCounts[entityType] = 1;
                    }
                });

                // because we can not access Maxkey from Item entity we have to set default value using this
                let selectedTab;
                if (Object.keys(entityTypeCounts).length > 0) {
                    selectedTab = Object.keys(entityTypeCounts)
                        .sort() // Sort keys alphabetically
                        .reduce((a, b) => entityTypeCounts[a] > entityTypeCounts[b] || (entityTypeCounts[a] === entityTypeCounts[b] && a < b) ? a : b);
                } else {
                    selectedTab = "";
                }
                this.setState({
                    allAssociations: jsonOutput,
                    selectedTab
                }, this.initializeSelectedDropdownValue);
            } else {
                // Nothing could be found, so the renderselectedTags will return nothing, not necessarily an error.
                this.setState({
                    allAssociations: jsonOutput
                });
            }
        }).catch((error) => {

            if (error instanceof Error) {
                Logger.error("Error getting entity associations: ", error);
            }
        });
    }

    private initializeSelectedDropdownValue(): void {
        const firstAssociation = this.state.allAssociations.find(
            (association: EntityAssociation) =>
                association.entityType.toLowerCase() === this.state.selectedTab
        );

        if (firstAssociation) {
            this.setState({ selectedDropdownValue: firstAssociation.entityName }, this.handleSelectedTag);
        }
    }

    public createMetadataTabs(): ReactNode {
        const customerConfig = this.state.customerConfig;
        const tabs: Array<any> = [];

        for (const customer in this.state.itemMetadata) {
            tabs.push(
                <Tab key={`${customer}`} eventKey={`${customer}`} title={`${customer}`}>
                    <MetadataListGroup metadata={this.state.itemMetadata?.[customer]} customer={customer} />
                </Tab>
            );
        }

        return (
            <MetadataTabs defaultActiveKey={`${customerConfig.name}`}>
                {tabs}
            </MetadataTabs>
        );
    }

    private renderSelectedTagItems(): ReactNode {
        if (!this.state.selectedTagItems) {
            return null;
        }

        const arrayOfAssociationsPerSelectedTab = this.state.allAssociations.filter((association: EntityAssociation) => association.entityType.toLowerCase() === this.state.selectedTab);

        if (arrayOfAssociationsPerSelectedTab.length === 0) {
            return null;
        }

        return (
            <MLTRow>
                <SelectedEntityCol md>
                    {this.renderDropdown(arrayOfAssociationsPerSelectedTab)}
                    <h5 className="mx-auto">{this.state.customerConfig.name} - Association: {this.state.selectedDropdownValue}</h5>
                    <MetadataCarousel
                        key={JSON.stringify(this.state.selectedTagItems)}
                        items={this.state.selectedTagItems}
                        customer={this.state.customerConfig}
                        username={this.props.username}
                        groups={this.props.groups}
                        lessItems
                        hasEntity={true}
                    />
                </SelectedEntityCol>
            </MLTRow>
        );
    }

    private async handleSelectedTag(): Promise<void> {
        try {
            let selectedTagItems = await requests.getItemsPerSelectedEntity(
                this.state.customerConfig,
                this.state.selectedDropdownValue,
                this.state.selectedTab,
                this.props.username,
                this.props.groups
            );
            selectedTagItems = selectedTagItems.filter(item => item.id !== this.state.seedId);

            this.setState({
                selectedTagItems
            });

        } catch (error) {
            if (error instanceof Error) {
                let errorMessage = error.message;
                if (errorMessage === "Failed to fetch") {
                    errorMessage = "Network error occurred while fetching data. Please try again. If the issue persists, you may continue with caution.";
                }
                this.setState({
                    showError: true,
                    error: errorMessage
                });
            } else {
                this.setState({
                    showError: true,
                    error: "An unknown error occurred."
                });
            }
        }
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    private renderDropdown(arrayOfAssociationsPerSelectedTab: any): React.ReactNode {
        const options = arrayOfAssociationsPerSelectedTab.map((association: EntityAssociation) => (
            <option key={association.entityName} value={association.entityName}>
                {association.entityName}
            </option>
        ));

        return (
            <DropdownContainer>
                <Dropdown
                    as="select"
                    onChange={this.handleDropdownChange}
                    value={this.state.selectedDropdownValue}
                >
                    {options}
                </Dropdown>
            </DropdownContainer>
        );
    }

    private handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ selectedDropdownValue: event.target.value }, this.handleSelectedTag);
    }

    private handleSelectedTab = (selectedTab: string) => {
        const singularTab = Metadata.tabMap[selectedTab as keyof typeof Metadata.tabMap];

        // If singularTab is not found, it means the value was already in singular form
        const updatedSelectedTab = singularTab ? singularTab : selectedTab;

        this.setState({ selectedTab: updatedSelectedTab });
    };

    public render(): ReactNode {
        const customerConfig = this.state.customerConfig;
        const selectedCustomerMetadata = this.state.itemMetadata?.[customerConfig.name];

        return (
            <div style={{ position: "relative" }}>
                <Container className="mw-100" key="metadata-container">
                    {!this.state.seedId &&
                        <Row>
                            <CentralCol>
                                Click on the <Icons.InfoCircleFill /> from search or recommendations to see metadata
                            </CentralCol>
                        </Row>
                    }
                    <LoadAlert
                        variant='danger'
                        show={this.state.showError}
                        onClose={this.closeError}
                        dismissible
                    >
                        {this.state.error}
                    </LoadAlert>
                    {this.state.seedId && this.state.customerConfig &&
                        <Row>
                            <MetadataCol md={3} className="mx-auto">
                                {this.state.itemMetadata &&
                                    <MetadataCard>
                                        <MetadataImage
                                            className="mx-auto"
                                            src={utils.getImageUrl(selectedCustomerMetadata)}
                                            alt={selectedCustomerMetadata["id"]}
                                            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => utils.getPlaceholderImage(event, selectedCustomerMetadata)}
                                        />
                                        <Card.Body>
                                            <Card.Title>{selectedCustomerMetadata["name"]}</Card.Title>
                                        </Card.Body>
                                        {this.createMetadataTabs()}
                                    </MetadataCard>
                                }
                            </MetadataCol>
                            <Col md={9}>
                                <Row className="justify-content-center" style={{ "marginTop": "10px" }}>
                                    {this.state.itemMetadata &&
                                        <EntitiesCard>
                                            <ItemEntityView
                                                seedId={this.state.seedId}
                                                customer={this.state.customerConfig.name}
                                                thing={utils.getThingFromMetadata(this.state.itemMetadata,
                                                    this.state.customerConfig.name)}
                                                origin={this.props.location.pathname}
                                                onTabSelect={this.handleSelectedTab}
                                            />
                                        </EntitiesCard>
                                    }
                                </Row>
                                {this.renderSelectedTagItems()}
                                {this.state.mltItems.length > 0 && <MLTRow>
                                    <MLTCol md>
                                        <h5 className="mx-auto">{this.state.customerConfig.name} - More Like This</h5>
                                        <MetadataCarousel
                                            items={this.state.mltItems}
                                            customer={this.state.customerConfig}
                                            username={this.props.username}
                                            groups={this.props.groups}
                                            lessItems
                                        />
                                    </MLTCol>
                                </MLTRow>}
                            </Col>

                        </Row>
                    }
                </Container>
            </div>
        );
    }
}

export default withRouter(Metadata);