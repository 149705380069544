import React, { ReactNode } from "react";
import { Alert, Button, Col, Form, InputGroup, Row, Modal, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Trash as ClearIcon, Search as SearchIcon, XCircle } from "react-bootstrap-icons";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import { SearchResults } from "../SearchResults";
import * as utils from "../../utils/Utils";
import { getSearchURL, makeSlotRequest, postPromotion } from "../../utils/Requests";
import Logger from "../../utils/Logger";
import moment from "moment";

import {DATE_FORMAT, STARTDATE_FORMAT, ENDDATE_FORMAT} from "../../constants";
import Branding from "../../config/Branding";

const ModalHeader = styled(Modal.Header)`
    padding-bottom: 0px;
`;

const DetailsRow = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
`;

const SearchRow = styled(Row)`
    border-top: gray thin solid;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const FormRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const FieldName = styled(InputGroup.Text)`
    background: black;
    min-width: 75px;
`;

const CentralCol = styled(Col)`
    text-align: center;
`;

const PromoAlert = styled(Alert)`
    position: absolute;
    top: 206px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

const CornerSelected = styled.div`
    position: fixed;
    z-index: 10;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: ${Branding.darkRed} transparent transparent transparent;
    cursor: pointer;
`;

const CornerX = styled(XCircle)`
    color: white;
    top: -45px;
    position: relative;
    left: 5px;
`;

type PromotionModalProperties = {
    customer: CustomerConfig,
    showModal: boolean,
    closeCallback: () => void,
    username: string,
    groups: string[],
    slots: Slot[],
    edit: boolean,
    duplicate?: boolean,
    selectedPromotion?: Record<string, any>,
    selectedPromotionItems?: Record<string,any>[]
}

type PromotionModalState = {
    promotionName: string,
    startDate: string,
    endDate: string,
    slotId: string,
    promotionItems: Array<Record<string, any>>,
    searchItems: Array<Record<string, any>>,
    searchTerm: string,
    searchLoading: boolean,
    searchLoaded: boolean,
    searchError?: Error | null,
    validated: boolean,
    showPromoError: boolean,
    promoError?: Error | null
}

export default class PromotionModal extends React.Component<PromotionModalProperties, PromotionModalState> {

    private timeoutId: NodeJS.Timeout | null;

    constructor(props: PromotionModalProperties) {
        super(props);

        this.timeoutId = null;

        const {edit, duplicate, selectedPromotion, selectedPromotionItems} = this.props;
        const startDate = moment().utc().format(DATE_FORMAT);
        const endDate = moment().utc().endOf("year").format(DATE_FORMAT);

        if (edit || duplicate) {
            this.state = {
                promotionName: duplicate ? `${selectedPromotion!.promotionName} Copy` : selectedPromotion!.promotionName,
                startDate: moment(selectedPromotion!.startDate).utc().format(DATE_FORMAT),
                endDate: moment(selectedPromotion!.endDate).utc().format(DATE_FORMAT),
                promotionItems: selectedPromotionItems!,
                slotId: selectedPromotion!.slot,
                searchTerm: "",
                searchItems: [],
                searchLoading: false,
                searchLoaded: false,
                validated: false,
                showPromoError: false
            };
        } else {
            this.state = {
                promotionName: "",
                startDate: startDate,
                endDate: endDate,
                promotionItems: [],
                slotId: "all",
                searchTerm: "",
                searchItems: [],
                searchLoading: false,
                searchLoaded: false,
                validated: false,
                showPromoError: false
            };
        }
    }

    private update = (): void => {
        this.setState({
            searchLoading: true,
            searchLoaded: false
        }, this.requestItems);
    }

    private handleSearchError(searchError: Error) {
        Logger.error(searchError);
        this.setState({
            searchLoading: false,
            searchLoaded: true,
            searchError
        });
    }

    private getUrl = (includeParams = true) => {
        const customer = this.props.customer;
        let url = getSearchURL(customer);

        if (includeParams) {
            const formattedParameters = utils.getFormattedParameters(`q: ${this.state.searchTerm}`);
            url += "?_debug_metadata=true&ignore=testtool" + formattedParameters;

            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);
        }

        return url;
    }

    public async requestItems(): Promise<void>  {
        await makeSlotRequest(this.getUrl(true))
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleSearchError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleSearchError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const items = result.items;

                            this.setState({
                                searchLoading: false,
                                searchLoaded: true,
                                searchItems: items || [],
                                searchError: null
                            });
                        }
                    });
                }
            }).catch((error) => {
                this.handleSearchError(error);
            });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
    }

    private searchReset = (): void => {
        this.setState({
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            searchError: null
        });
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value
        });
    }

    private renderSearchResults(): ReactNode {
        const {searchError, searchLoading, searchLoaded} = this.state;

        if (searchError) {
            return <Row className="justify-content-center">
                Error - {searchError.message}
            </Row>;
        }

        if (searchLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!searchLoading && !searchLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        }
    }

    private selectItem = (item: Record<string,any>): void => {
        let { promotionItems } = this.state;
        const itemExists = promotionItems.find((promoItem) => { return item.id === promoItem.id; });

        if (itemExists) {
            promotionItems = promotionItems.filter((promoItem) => {
                return item.id !== promoItem.id;
            });
        } else {
            promotionItems.push(item);
        }

        this.setState({
            promotionItems
        });
    }

    private setPromotionName = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            promotionName: event.currentTarget.value
        });
    }

    private setStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            startDate: event.currentTarget.value
        });
    }

    private setEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            endDate: event.currentTarget.value
        });
    }

    private setSlotId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotId: event.currentTarget.value
        });
    }

    private savePromotion = async () => {
        const {edit, selectedPromotion} = this.props;
        const promotionId = edit ? selectedPromotion!.promotionId : utils.generateGuid();

        const promotions = this.state.promotionItems.map((promotion, index) => {
            return {
                "id": promotion.id,
                "position": index
            };
        });

        const promotionContent = {
            "metadata": promotions
        };

        await postPromotion(
            this.props.customer,
            promotionId,
            this.state.promotionName,
            moment(this.state.startDate).utc().format(STARTDATE_FORMAT),
            moment(this.state.endDate).utc().format(ENDDATE_FORMAT),
            this.state.slotId,
            promotionContent,
            edit ?  selectedPromotion!.createdBy : undefined,
            edit ?  selectedPromotion!.dateCreated : undefined
        ).then((response) => {
            if (response.ok) {

                if (response.status === 200) {
                    this.resetStateAndClose();
                } else {
                    this.handleSaveError(Error(`Error during save, API response: ${response.status} - ${response.statusText}`));
                }
            }
        }).catch((error: Error) => {
            this.handleSaveError(error);
        });
    }

    private handleSaveError(error: Error) {
        Logger.error(error);
        this.setState({
            showPromoError: true
        });
    }

    private closeError = (): void => {
        this.setState({
            showPromoError: false
        });
    }

    private resetStateAndClose() {
        const startDate = moment().utc().format(DATE_FORMAT);
        const endDate = moment().utc().endOf("year").format(DATE_FORMAT);

        this.setState({
            promotionName: "",
            startDate,
            endDate,
            promotionItems: [],
            slotId: "all",
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            searchError: null,
            validated: false,
            showPromoError: false,
            promoError: null
        }, () => {
            this.props.closeCallback();
        });
    }

    private closeModal = (): void => {
        this.resetStateAndClose();
    }

    public render(): ReactNode {

        const availableSlots = this.props.slots.sort((a,b) => {
            return a.slotName.localeCompare(b.slotName);
        }).map((slot) => {
            return (
                <option value={slot.slotId} key={slot.slotId}>
                    {slot.slotName} ({slot.slotId})
                </option>
            );
        });

        availableSlots.push(
            <option value={"all"} key={"all"}>
                All
            </option>
        );

        return (
            <Modal
                show={this.props.showModal}
                centered
                backdrop="static"
                dialogClassName="modal-80w"
                onHide={this.closeModal}
            >
                <ModalHeader closeButton>
                    <Modal.Title>{this.props.edit ? "Edit Promotion" : this.props.duplicate ? "Duplicate Promotion" : "Add New Promotion"}</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <PromoAlert
                        variant="danger"
                        show={this.state.showPromoError}
                        onClose={this.closeError}
                        dismissible
                    >
                        Error saving promotion
                    </PromoAlert>
                    <DetailsRow>
                        <Col className="mx-auto" md={4} style={{ marginLeft: "10px", marginRight: "10px"}}>
                            <Form noValidate validated={this.state.validated} onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    this.setState({
                                        validated: true
                                    });

                                    const formValid = event.currentTarget.checkValidity();
                                    if (formValid) {
                                        this.savePromotion();
                                    }
                                }}>
                            <FormRow>
                                <Col>
                                    <InputGroup hasValidation>
                                        <InputGroup.Prepend>
                                            <FieldName>Name</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="input"
                                            id="promotion-name"
                                            required
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.promotionName}
                                            placeholder="Name of promotion"
                                            onChange={this.setPromotionName}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a Promotion Name.
                                    </Form.Control.Feedback>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>From</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="date"
                                            id={"promotion-from"}
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.startDate}
                                            onChange={this.setStartDate}
                                        />
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>To</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="date"
                                            id="promotion-to"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.endDate}
                                            onChange={this.setEndDate}
                                        />
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Slot</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            id="dropdown-slot"
                                            title="Slot"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.slotId}
                                            onChange={this.setSlotId}
                                        >
                                            {availableSlots}
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </FormRow>

                            <FormRow>
                                <Col className="d-flex justify-content-end">
                                    <Button type="submit">{this.props.edit ? "Save" : "Create"}</Button>
                                    <Button variant="secondary" onClick={() => {this.resetStateAndClose();}} style={{marginLeft: "5px"}}>Cancel</Button>
                                </Col>
                            </FormRow>
                            </Form>
                        </Col>
                        <Col md={8} style={{borderLeft: "thin gray solid"}}>
                            <h6>Promoted Items (Drag to Re-order)</h6>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided: any, snapshot: any) => (
                                    <div
                                    ref={provided.innerRef}
                                    style={this.getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                    >
                                    {this.state.promotionItems.map((item, index: number) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={this.getDraggableItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            >
                                                <div>
                                                    <CornerSelected onClick={() => { this.selectItem(item); }}>
                                                        <CornerX size={20} />
                                                    </CornerSelected>
                                                    <img
                                                        alt={item.title}
                                                        src={utils.getImageUrl(item)} style={this.getPromoItemStyle(item)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>

                        </Col>
                    </DetailsRow>
                    <SearchRow>
                        <CentralCol md>
                            <FormRow style={{paddingBottom: "10px"}}>
                            <Col md={10} className="mx-auto">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Search</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="input"
                                            id="search-string"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.searchTerm}
                                            onChange={this.setSearchTerm}
                                            onKeyPress={this.checkKey}
                                            onKeyUp={this.checkBackspaceKey}
                                            placeholder="Enter Search Terms here, e.g. Title, Actor"
                                            autoComplete="off"
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                                                <Button variant="primary" type="button" onClick={this.update}>
                                                    <SearchIcon />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="reset=tooltip">Reset search</Tooltip>}>
                                                <Button variant="dark" type="button" onClick={this.searchReset}>
                                                    <ClearIcon />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <div style={{overflowY: "scroll", height: "45vh"}}>
                            {this.renderSearchResults()}
                            {this.state.searchLoaded &&

                                    <SearchResults
                                        customer={this.props.customer}
                                        showMetadataLink={false}
                                        showOverlay={false}
                                        items={this.state.searchItems}
                                        username={this.props.username}
                                        groups={this.props.groups}
                                        selectCallback={this.selectItem}
                                        promotedItems={this.state.promotionItems}
                                        smallImages
                                    />
                                }
                            </div>
                        </CentralCol>
                    </SearchRow>
                </Modal.Body>
            </Modal>
        );
    }

    private onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }

        const items = this.reorder(
          this.state.promotionItems,
          result.source.index,
          result.destination.index
        );

        this.setState({
            promotionItems: items
        });
    };

    private reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    private getPromoItemStyle(item: Record<string, any>): Record<string, any> {
        let itemStyle: Record<string, any> = {};

        if (this.props.customer?.portraitImages) {
            itemStyle = {
                height: "175px",
                width: "unset"
            };
        } else {
            itemStyle = {
                height: "105px",
                width: "185px"
            };
        }

        if (item["typeName"] === "user" || item["typeName"] === "cluster") {
            itemStyle["background"] = Branding.darkBackground;
        }

        return itemStyle;
    }

    private getDraggableItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        padding: "3px",
        borderRadius: "3px",
        margin: "0px 5px 5px 0",

        // change background colour if dragging
        background: isDragging ? Branding.primary : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    private getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? Branding.draggableBackground : Branding.darkBackground,
        display: "flex",
        padding: "6px",
        flexWrap: "wrap",
        borderRadius: "3px"
    });
}
